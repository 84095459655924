import React, { useEffect, useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap/'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { EDIT_DOCUMENT } from '../../../utils/mutations'
import { GET_DOCUMENT_BY_ID } from '../../../utils/GQLqueries'
import { useToasts } from 'react-toast-notifications'
import { formatDateOnly } from '../../../utils/globalHelper'
import ICoaProduct from '../../common/models/interfaces/ICoaProduct'
import ICoaDocument from '../../common/models/interfaces/ICoaDocument'

const EditCOA = (props: any) => {
  const [editDocument] = useMutation(EDIT_DOCUMENT)
  const [name, setName] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [uploadedOn, setUploadedOn] = useState('')
  const [isApproved, setIsApproved] = useState(false)
  const [isArchived, setIsArchived] = useState(false)
  const [notes, setNotes] = useState('')
  const { coaProductId, coaDocumentId } = useParams<{ coaProductId: any; coaDocumentId: any }>()
  const coaProductIdInt = parseInt(coaProductId)
  const coaDocumentIdInt = parseInt(coaDocumentId)
  const coaProduct: ICoaProduct = props.location.coaProduct
  let coaDocument: ICoaDocument = props.location.coaDocument

  const { addToast } = useToasts()
  const history = useHistory()
  const ToastError = (mes: string) => {
    addToast(`${mes}. Please refresh the page. Contact IT if the problem continues.`, {
      appearance: 'error',
    })
  }

  useEffect(() => {
    if (coaDocument) {
      setName(coaDocument.name)
      setFileUrl(coaDocument.fileUrl)
      setUploadedOn(formatDateOnly(coaDocument.uploadedOn))
      setIsApproved(coaDocument.isApproved)
      setIsArchived(coaDocument.isArchived)
      setNotes(coaDocument.notes)
    }
  }, [])

  //error handling
  const [hasBlankName, setHasBlankName] = useState(false)

  const { error, loading, data } = useQuery(GET_DOCUMENT_BY_ID, {
    variables: {
      coaDocumentId: coaDocumentIdInt,
    },
  })

  useEffect(() => {
    if (data?.coaDocuments) {
      coaDocument = data.coaDocuments[0]
    } else if (error) {
      ToastError('The Information Failed To Load')
    }
  }, [data, error])

  const handleSaveCoa = async (event: any) => {
    event.preventDefault()
    if (!name) {
      handleValidation()
    }
    try {
      if (name) {
        await editDocument({
          variables: {
            input: {
              coaDocumentId: coaDocumentIdInt,
              name,
              uploadedOn,
              fileUrl,
              isApproved,
              isArchived,
              notes,
            },
          },
        })
        addToast('COA Document Updated Successfully.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setName('')
        redirect()
      }
    } catch (e) {
      console.error(e)
      addToast('Error Occurred While Updating COA Document.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleValidation = () => {
    if (!name) {
      setHasBlankName(true)
    } else {
      setHasBlankName(false)
    }
  }

  const handleName = (event: any) => {
    setName(event.target.value)
    handleValidation()
  }

  const handleFileUrl = (event: any) => {
    setFileUrl(event.target.value)
  }

  const handleIsApproved = (event: any) => {
    setIsApproved(event.target.checked)
  }

  const handleIsArchived = (event: any) => {
    setIsArchived(event.target.checked)
  }

  const redirect = () => {
    const location = {
      pathname: `/COA/${coaProductIdInt}/Documents`,
      state: { from: 'Edit View' },
    }
    history.push(location)
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <PageWrapper>
      <Row className='text-left'>
        <Col xs={6} md={10}>
          <h1 className='text-secondary'>Edit COA Document</h1>
        </Col>
        <Col md={2} xs={6}>
          <Link to={{ pathname: `/COA/${coaProductIdInt}/Documents`, state: { from: 'Edit View' } }} style={{ display: 'flex', alignSelf: 'center' }}>
            Back to list
          </Link>
        </Col>
      </Row>
      <Row className='text-left'>
        <Col xl={2}>
          <p className='text-secondary'>Product</p>
        </Col>
        <Col xl={2}>
          <p className='text-secondary'>{coaProduct.name}</p>
        </Col>
        <Col xl={1}></Col>
        <Col xl={1}></Col>
        <Col xl={1}>
          <p className='text-secondary'>Region</p>
        </Col>
        <Col xl={1}>
          <p className='text-secondary'>{coaProduct.countryRegion.name}</p>
        </Col>
        <Col xl={2}></Col>
        <Col xl={2}></Col>
      </Row>
      <SolidLine />

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Name</p>
        </Col>
        <Col xl={2}>
          <input type='text' value={name} onChange={handleName} />
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>
      <Row>{hasBlankName && <small className='form-text text-danger'>Name cannot be blank.</small>}</Row>

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>File URL</p>
        </Col>
        <Col xl={6}>
          <p className='text-secondary'>{fileUrl}</p>
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Uploaded On</p>
        </Col>
        <Col xl={6}>
          <p className='text-secondary'>{uploadedOn}</p>
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Sort Order</p>
        </Col>
        <Col xl={6}>
          <p className='text-secondary'>{coaDocument.sortOrder}</p>
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Is Approved</p>
        </Col>
        <Col xl={2}>
          <CheckBox checked={isApproved} onChange={handleIsApproved} type='checkbox' />
        </Col>
        <Col xl={2}></Col>
        <Col xl={2}>
          <p className='text-secondary'>Is Archived</p>
        </Col>
        <Col xl={2}>
          <CheckBox checked={isArchived} onChange={handleIsArchived} type='checkbox' />
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={8}>
          <p className='text-secondary'>Notes</p>
          <textarea
            rows={4}
            style={{ width: '100%', border: '1px solid #0F4B8F' }}
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='text-left mt-5'>
        <Col xl={3}>
          <SaveButton onClick={handleSaveCoa}>Save</SaveButton>
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default EditCOA

const PageWrapper = styled.div`
  max-width: 1475px;
  width: 100%;
  font-size: 18px;
`

const SolidLine = styled.div`
  border-top: 10px;
  border: solid;
  color: #c8c8c8;
  border-width: 1px;
`

const CustomButton = styled.button`
  background-color: #09497e;
  color: white;
  font-size: 14px;
  margin: 3px;
  border: none;
  padding: 2px 15px;
  font-weight: 500;
  width: 150px;
  text-align: center;

  &:hover {
    background: #345880;
    border: 2px solid #022b53;
    padding: 0px 13px;
  }
`

const SaveButton = styled.button`
  background-color: #09497e;
  color: white;
  font-size: 18px;
  margin: 3px;
  border: none;
  font-weight: 500;
  width: 175px;
  height: 40px;
  text-align: center;

  &:hover {
    background: #345880;
    border: 2px solid #022b53;
  }
`

const CheckBox = styled.input`
  background-color: #09497e;
  width: 18px;
  height: 18px;

  &:checked {
    background-color: #09497e !important;
  }

  &:hover {
    background: #345880;
    border: 2px solid #022b53;
    padding: 0px 13px;
  }
`
