import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { IType } from "../DashBoard.interface";

interface IProps {
  list: IType[];
  openModal: () => void;
  onClickType: (type: IType) => void;
}

const SideBar = ({ list, openModal, onClickType }: IProps): JSX.Element => {
  const [mainList, setMainList] = useState<IType[]>(list);
  const [search, setSearch] = useState<string>("");
  const [currentType, setCurrentType] = useState<IType>();

  const handleSearch = (value: string) => {
    setSearch(value);
    onFilterList(value);
  };

  const handleModal = () => {
    openModal();
  };

  const onFilterList = (value: string) => {
    const filterList: IType[] = list.filter(({ name }) =>
      name.en.toLowerCase().includes(value.toLowerCase())
    );
    setMainList(filterList);
  };

  useEffect(() => {
    if (currentType) {
      onClickType(currentType);
    }
  }, [currentType]);

  useEffect(() => {
    setMainList(list);
  }, [list]);

  useEffect(() => {
    setCurrentType((prev) => {
      return mainList.some((item) => item.productAttributeTypeId === prev?.productAttributeTypeId) ? prev : mainList[0]

    });
  }, [mainList]);

  return (
    <Wrap>
      <h3>Attributes</h3>
      <CustomButton className="btn" onClick={() => handleModal()}>
        Add Attribute Type
      </CustomButton>
      <Form.Control
        className="input"
        type="text"
        value={search}
        placeholder="Search"
        onChange={(e) => handleSearch(e.target.value)}
      />
      <div className="list">
        <div className="list__header">
          <span>Name</span>
          <span>Count</span>
        </div>

        <ul>
          {mainList.map((item) => (
            <li
              key={item.productAttributeTypeId}
              onClick={() => setCurrentType(item)}
              className={item.productAttributeTypeId === currentType?.productAttributeTypeId ? "active" : ""}
            >
              <span>{item.name.en}</span>
              <span>{item.attributeCount}</span>
            </li>
          ))}
        </ul>
      </div>
    </Wrap>
  );
};

export default SideBar;

const CustomButton = styled(Button)`
  background-color: #0f4b8f;
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;

  .btn {
    margin: 10px 0 20px;
  }

  .input {
    border: 1px solid #6f93bc !important;
    margin-bottom: 10px;
  }

  .list {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    ul {
      list-style: none;
      padding: 10px 0;
      border: 1px solid #000000;
      overflow-y: auto;
      li {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
        &:hover,
        &.active {
          background-color: #0f4b8f;
          color: #ffffff;
        }
      }
    }
  }
`;
