/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ChevronDoubleLeft } from 'react-bootstrap-icons'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import { ADD_PRODUCT_ATTRIBUTE, GET_PRODUCT, GET_PRODUCT_PARENT, REMOVE_PRODUCT_ATTRIBUTE } from '../../../utils/shopping/Shopping.Product.Queries'
import { GET_ATTRIBUTE_TYPES } from '../../../utils/shopping/Shopping.ProductTypes.Queries'
import { ProductProvider } from './component-state/productState'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Chip from '../../../GlobalComponents/Chip'
import Attributes from './Attributes/Attributes'

type ProductParams = {
  id: string
}

const ProductDetails = () => {
  const { id } = useParams<ProductParams>()
  const hsitory = useHistory()
  const { addToast } = useToasts()
  const [parentProduct, setParentProduct] = useState<any>()
  const [product, setProduct] = useState<any>()
  const [filter, setFilter] = useState<number | null>(null)
  const [previousUrl, setPreviousUrl] = useState<string | null>(null)
  const [inputList, setInputList] = useState<Array<any>>([])
  const [updateAttributes, setUpdateAttributes] = useState<Array<any>>([])
  const [existing, setExisting] = useState<Array<any>>([])
  const [key, setKey] = useState('attributes')
  const [resetAttribute, setResetAttributes] = useState(false)
  const [productId, setProductId] = useState('')

  const { data, refetch } = useQuery(GET_PRODUCT, {
    variables: { productId: Number(productId) },
    fetchPolicy: 'no-cache',
  })
  const { data: productTypesData } = useQuery(GET_ATTRIBUTE_TYPES, {
    variables: { allowedOn: [2, 3] },
  })
  const [execute, { data: productParentData }] = useLazyQuery(GET_PRODUCT_PARENT)
  const [addProductAttributeProduct] = useMutation(ADD_PRODUCT_ATTRIBUTE)
  const [removeProductAttributeProduct] = useMutation(REMOVE_PRODUCT_ATTRIBUTE)

  useEffect(() => {
    if (resetAttribute) {
      setUpdateAttributes([])
      setResetAttributes(false)
    }
  }, [resetAttribute])

  useEffect(() => {
    id && setProductId(id)
  }, [id])

  useEffect(() => {
    id && data && setProduct(data.products[0])
  }, [data])

  useEffect(() => {
    if (product) {
      const extAttr = product.productAttributes.map((a: any) => {
        return { name: a.name.en, productAttributeId: a.productAttributeId }
      })
      setExisting(extAttr)
    }
  }, [product])

  useEffect(() => {
    productTypesData && productTypesData.productAttributeTypes && setInputList(productTypesData.productAttributeTypes)
  }, [productTypesData])

  useEffect(() => {
    productParentData && setParentProduct(productParentData.productParents.items[0].inventory)
  }, [productParentData])

  useEffect(() => {
    if (data && data.products.length > 0) {
      setFilter(data.products[0].productParentId)
      if (filter) {
        execute({ variables: { productParentId: filter } })
      }
    }
  }, [data, filter])

  useEffect(() => {
    handleNavigationState(history)
  }, [])

  const handleNavigationState = (history: { state: { state: { previousLocation: string } } }) => {
    if (history.state) {
      setPreviousUrl(history.state.state.previousLocation)
    }
  }

  const handleUpdateProductSeleted = (e: any) => {
    const selectedProduct = Number(e.target.value)
    execute({ variables: { productParentId: filter } })
    const currentSelectedSku: any = productParentData.productParents.items[0].products.find((p: any) => p.inventory.inventoryId === selectedProduct)
    setProduct(currentSelectedSku)
    setProductId(currentSelectedSku.productId)
  }

  const updatedAttributes = async (attrIds: Array<number>) => {
    try {
      await addProductAttributeProduct({
        variables: {
          input: {
            productId: product.productId,
            productAttributeIds: attrIds,
          },
        },
      })
      addToast(`Attribute Updated Successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      })
      refetch()
    } catch (error) {
      addToast(`An Error Occured While Saving.`, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleRemoveAttibutes = async (attrIds: Array<number>) => {
    try {
      await removeProductAttributeProduct({
        variables: {
          input: {
            productId: product.productId,
            productAttributeIds: attrIds,
          },
        },
      })
      addToast(`Attribute Updated Successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      })
      refetch()
    } catch (error) {
      addToast(`An Error Occured While Saving.`, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const ensureChanges = (mappedOne: Array<number>, mappedTwo: Array<number>) => {
    const delatArry = []
    mappedTwo.forEach((ele) => {
      if (!mappedOne.includes(ele)) {
        delatArry.push(ele)
      }
    })
    return mappedOne.length !== mappedTwo.length || delatArry.length > 0
  }

  const determineRemoved = (mappedOne: Array<number>, mappedTwo: Array<number>) => {
    const tempArry: any = []
    mappedOne.forEach((ele: any) => {
      !mappedTwo.includes(ele) && tempArry.push(ele)
    })

    return [tempArry.length > 0, tempArry]
  }

  const handleSaveAttributes = () => {
    if (product && product.productId) {
      const attrIds = updateAttributes.map((attr) => {
        return attr.productAttributeId
      })
      const mappedOne = existing.map((ele: any) => {
        return ele.productAttributeId
      })
      const mappedTwo = updateAttributes.map((ele: any) => {
        return ele.productAttributeId
      })

      const _eval = ensureChanges(mappedOne, mappedTwo)
      if (!_eval) {
        addToast(`No Updates To Save.`, {
          appearance: 'info',
          autoDismiss: true,
        })
        return
      }
      const evaluation = determineRemoved(mappedOne, mappedTwo)
      if (evaluation[0]) {
        handleRemoveAttibutes(evaluation[1])
      } else {
        updatedAttributes(attrIds)
      }
    }
  }

  return (
    <ProductProvider>
      <ProductWrapper fluid>
        <Row>
          <Col lg={12}>
            <div className='top-nav-wrapper'>
              <Link to={previousUrl ? previousUrl : '/Shopping/ParentProducts'} className='top-nav-link'>
                <div className='top-nav-inner-wrapper'>
                  <div className='top-nav-icon-wrapper'>
                    <ChevronDoubleLeft />
                  </div>
                  <div className='top-nav-text-wrapper'>
                    {' '}
                    {previousUrl ? (
                      <p>
                        Back to Parent Product&nbsp;
                        {parentProduct && parentProduct.productSku && `(${parentProduct.productSku})`}
                      </p>
                    ) : (
                      <p>Back to Product List</p>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
        {previousUrl &&
          productParentData &&
          productParentData.productParents &&
          productParentData.productParents.items[0] &&
          productParentData.productParents.items[0].products &&
          productParentData.productParents.items[0].products.length > 1 && (
            <Row className='product-select-wrapper'>
              <Col lg={6}>
                <select
                  className='product-select-input'
                  value={product?.inventory.inventoryId}
                  onChange={(e) => {
                    handleUpdateProductSeleted(e)
                  }}
                >
                  <option disabled selected>
                    Switch Product
                  </option>
                  {productParentData &&
                    productParentData.productParents &&
                    productParentData.productParents.items[0] &&
                    productParentData.productParents.items[0].products.length > 0 &&
                    productParentData.productParents.items[0].products.map((product: any, index: number) => {
                      return (
                        <option key={index} value={product.inventory.inventoryId}>
                          {product.inventory.inventoryId} - {product.inventory.name.toUpperCase()}
                        </option>
                      )
                    })}
                </select>
              </Col>
            </Row>
          )}
        <Row className='top-section'>
          <Col lg={12}>
            <h3 className='page-title'>Product Details</h3>
          </Col>
        </Row>
        <Row style={{ minHeight: '10vh' }}>
          <Col lg={7}>
            <Row>
              <Col sm={4}>
                <p className='bold-lbl-txt'>Name</p>
                <p className='bold-lbl-txt'>Freedom Inventory Id</p>
                <p className='bold-lbl-txt'>Freedom SKU</p>
                <p className='bold-lbl-txt'>Parent</p>
              </Col>
              <Col sm={8}>
                <p>{product && product.inventory && product.inventory.description}</p>
                <p>{product?.productParentId}</p>
                <p>
                  <a
                    href={`https://admin.securefreedom.com/Zilis/inventory/invprices.asp?InventoryID=${
                      product && product.inventory && product.inventory.inventoryId
                    }`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {product && product.inventory && product.inventory.inventoryId}
                  </a>
                </p>
                <p>
                  {productParentData &&
                    productParentData.productParents &&
                    productParentData.productParents.items &&
                    productParentData.productParents.items.length > 0 &&
                    productParentData.productParents.items[0].inventory.productSku}
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={5}>
            <Row>
              <Col sm={4}>
                <p className='bold-lbl-txt'>Attributes</p>
              </Col>
              <Col sm={8}>
                <div className='attr-chip-container'>
                  {product &&
                    product.productAttributes &&
                    product?.productAttributes.map((attr: any, index: number) => {
                      return (
                        <div key={index} className='attr-chip-wrapper'>
                          <Chip label={attr.name.en} color={attr.productAttributeType.backgroundColor} size={'LARGE'} />
                        </div>
                      )
                    })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='zls-divider'></div>
        <Row>
          <Col lg={12}>
            <Tabs id='controlled-tab' activeKey={key} onSelect={(k: any) => setKey(k)} className='mb-3 custom-tab' fill>
              <Tab eventKey='attributes' title='Attributes'>
                <Attributes
                  inputList={inputList}
                  setUpdateAttributes={setUpdateAttributes}
                  setResetAttributes={setResetAttributes}
                  existingProductAttributes={existing}
                  handleSaveAttributes={handleSaveAttributes}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </ProductWrapper>
    </ProductProvider>
  )
}

export default ProductDetails

const ProductWrapper = styled(Container)`
  text-align: left;
  font-size: 18px;

  .product-select-wrapper {
    height: 75px;
    margin-top: 2%;

    .product-select-input {
      height: 45px;
      width: 275px;
      padding-left: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .top-nav-wrapper {
    margin-bottom: 20px;

    .top-nav-link {
      font-size: 20px;
      text-decoration: none;

      .top-nav-inner-wrapper {
        display: flex;
        align-items: center;

        .top-nav-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .top-nav-text-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .zls-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .top-section {
    margin-bottom: 2%;
    .page-title {
      font-size: 36px;
      color: rgb(92, 90, 90);
      font-weight: 450;
    }
  }

  .bold-lbl-txt {
    font-weight: 600;
  }

  .attr-chip-container {
    display: flex;
    .attr-chip-wrapper {
      margin: 1px 2px;
    }
  }

  .custom-tab .nav-link.active {
    background-color: #0e4b8f;
    color: #fff;
  }
`
