/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './auth/PrivateRoute'
import './App.css'
import Page from './GlobalComponents/PageWrapper'
import { APP_STARTED } from './redux/actions/app/appActionTypes'
import { useQuery } from '@apollo/react-hooks'

import { SkincareAdminPermissions } from './redux/actions/Skincare/skincareActions'
import AllRoutes from './routes'

import { GET_PERMISSIONS_BY_EMAIL } from './utils/GQLqueries'

function App() {
  const dispatch = useDispatch()
  const { email } = useSelector((state) => state.azProfile)
  const { data } = useQuery(GET_PERMISSIONS_BY_EMAIL, { variables: { email } })

  useEffect(() => {
    if (data?.users[0].userPermissions) {
      let AllPermissions = data.users[0].userPermissions
      dispatch(SkincareAdminPermissions(AllPermissions))
      dispatch({ type: APP_STARTED })
    }
  }, [data?.users[0].userPermissions])

  return (
    <div className='app'>
      <Page>
        <Switch>
          {AllRoutes.map((route, index) => {
            return route.protected ? (
              <PrivateRoute key={index} path={route.path} component={route.component} />
            ) : (
              <Route key={index} path={route.path} exact={route.exact} render={(props) => <route.component {...props} />} />
            )
          })}
        </Switch>
      </Page>
    </div>
  )
}

export default App
