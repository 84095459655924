const getComponentData = (state) => {
  const { skincarePermissions} = state.entries;
  // areaId will be changed when we have areaId for catagories
  const glowPermissions = skincarePermissions?.find(
    (skincarePermission) => skincarePermission.permissionAreaId === 8
  );
  const view =
    glowPermissions?.permissionLevelId === 2 ||
    glowPermissions?.permissionLevelId === 3 ||
    glowPermissions?.permissionLevelId === 4;
  const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;

  return {
    view,
    edit,
  };
};

export default getComponentData;
