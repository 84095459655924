import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap/'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ConfirmDel from '../common/ConfirmDel'
import { formatDateOnly } from '../../utils/globalHelper'

function COAProduct(props: any) {
  const handleUpdateAfterDelete = () => {
    props.getProductsAfterDelete()
  }
  const productName = props.product.name
  const updated = formatDateOnly(props.product.lastUpdatedOn)
  const [showDel, setShowDel] = useState('hide')

  const showDelete = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    showDel === 'hide' ? setShowDel('show') : setShowDel('hide')
  }
  return (
    <div>
      <Row className='products'>
        <Col>{props.product.coaProductId}</Col>
        <Col>{productName}</Col>
        <Col>{props.product.coaSection.name}</Col>
        <Col>{props.product.countryRegion.name}</Col>
        <Col>{updated}</Col>
        {props.edit && (
          <Col>
            {' '}
            <Link to={{ pathname: `/COA/${props.product.coaProductId}/Documents`, state: props.product }}>
              <button id='edit'>View</button>
            </Link>{' '}
            |{' '}
            <button id='edit' onClick={showDelete}>
              Delete
            </button>{' '}
          </Col>
        )}
      </Row>
      <Delete>
        <div className={showDel}>
          <ConfirmDel
            product={props.product}
            type={'Product'}
            fetch={props.fetch}
            show={showDelete}
            handleUpdateAfterDelete={handleUpdateAfterDelete}
            name={productName}
          />
        </div>
      </Delete>
      <Overlay showDel={showDel} />
    </div>
  )
}

export default COAProduct

const Overlay = styled.div<{ showDel: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => (props.showDel === 'show' ? 'visible' : 'hidden')};
`

const Delete = styled.div`
  .hide {
    display: none;
  }

  .show {
    z-index: 11;
    position: absolute;
    left: 35%;
    top: 35%;
    background: white;
    border: 1px solid gray;
    padding: 3%;
    box-shadow: 2px 3px 5px gray;
    max-width: 500px;
  }
`
