import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { IAttribute, IAttributeItem } from "../DashBoard.interface";

interface IProps {
  show: boolean;
  attribute: IAttribute | null;
  onHide: () => void;
  onSave: (e: IAttributeItem) => void;
  onSaveEdit: (e: IAttributeItem, attribute: any) => void;
}

const defaultFormValue = {
  name: { en: "" },
  sortOrder: 0,
};

const ModalAddAttribute = ({
  attribute,
  show,
  onSave,
  onHide,
  onSaveEdit,
}: IProps): JSX.Element => {
  const [formValue, setFormValue] = useState<IAttributeItem>(defaultFormValue);
  const [editAttribute, setEditAttribute] = useState<boolean>(false);

  const handleChange = (e: any) => {
    let { value } = e.target;
    const { name } = e.target;
    if (name === "name") {
      value = { en: value };
    }
    setFormValue({ ...formValue, [name]: value });
  };
  const [formValid, setFormValid] = useState<boolean>(false);

  const onHideModal = (state = false) => {
    if (state) {
      if (editAttribute) {
        onSaveEdit(formValue, attribute);
      } else {
        onSave(formValue);
      }
    }
    onHide();
    resetFormValue();
  };

  const resetFormValue = () => {
    setFormValue(defaultFormValue);
  };

  useEffect(() => {
    if (attribute) {
      const { name, sortOrder } = attribute;
      setFormValue(() => ({ name, sortOrder }));
      setEditAttribute(true);
    }
  }, []);

  useEffect(() => {
    if (
      formValue.name.en !== "" &&
      formValue.sortOrder !== null &&
      formValue.sortOrder !== undefined
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formValue]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Attribute</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              onChange={handleChange}
              name="name"
              value={formValue.name.en}
              isInvalid={!formValid}
            />
            <Form.Control.Feedback type="invalid">
              Please choose valid name.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Sort
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="number"
              name="sortOrder"
              value={formValue.sortOrder}
              onChange={handleChange}
              isInvalid={!formValid}
            />
            <Form.Control.Feedback type="invalid">
              Please choose a sort number.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHideModal(true)} disabled={!formValid}>
          Save
        </Button>
        <Button onClick={() => onHideModal()}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddAttribute;
