const getComponentData = (state) => {

    const { skincarePermissions, PermissionsError} = state.entries;
    const glowPermissions = skincarePermissions?.find((skincarePermission) => skincarePermission.permissionAreaId === 10);
  
    const view = glowPermissions?.permissionLevelId === 2 || glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;
    const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;
  
    return {
      view,
      edit,
      PermissionsError,
    };
  };
  
  export default getComponentData;
  