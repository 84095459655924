import React, { useEffect, useState } from "react";
import styled from "styled-components";
interface ITransferListProps {
  mainList: any;
  existingProductAttributes: any;
  setUpdateAttributes: any;
  reset: boolean;
  setReset: any;
}

const TransferList = ({
  mainList,
  existingProductAttributes,
  setUpdateAttributes,
  reset,
  setReset,
}: ITransferListProps) => {
  const [selectedList, setSelectList] = useState<Array<any>>([]);

  useEffect(() => {
    existingProductAttributes && setSelectList(existingProductAttributes);
  }, [existingProductAttributes]);

  useEffect(() => {
    if (reset) {
      setSelectList(existingProductAttributes);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    selectedList && setUpdateAttributes(selectedList);
  }, [selectedList]);

  const setFromMainToSelected = (entry: any) => {
    const tempArray = [...selectedList];
    const newAttributes: Array<any> = [];
    if (tempArray.length === 0) {
      tempArray.push(entry);
    } else {
      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].name !== entry.name) {
          if (newAttributes.length === 0) {
            newAttributes.push(entry);
          } else {
            for (let j = 0; j < newAttributes.length; j++) {
              newAttributes[j].name !== entry.name && newAttributes.push(entry);
            }
          }
        } else {
          tempArray.splice(i, 1);
          for (let j = 0; j < newAttributes.length; j++) {
            if (newAttributes[j].name === entry.name)
              newAttributes.splice(j, 1);
          }
        }
      }
    }
    const combineList = [...tempArray, ...newAttributes];
    setSelectList(combineList);
  };

  const isSelected = (value: string) => {
    const val = selectedList.find((attr) => attr.name === value);
    return val ? true : false;
  };

  return (
    <TranferOuterWrapper>
      <div style={{ margin: "0 8% 0 0" }}>
        <ListHeader>
          <h2>Main List</h2>
        </ListHeader>
        <ListContiner>
          {mainList &&
            mainList.length > 0 &&
            mainList.map((entry: any, index: any) => {
              return (
                <ListItem
                  itemSelected={isSelected(entry.name.en)}
                  key={index}
                  onClick={() =>
                    setFromMainToSelected({
                      name: entry.name.en,
                      productAttributeId: entry.productAttributeId,
                    })
                  }
                >
                  {entry.name.en}
                </ListItem>
              );
            })}
        </ListContiner>
      </div>

      <div>
        <ListHeader>
          <h2>Assigned List</h2>
        </ListHeader>
        <ListContiner>
          {selectedList &&
            selectedList.length > 0 &&
            selectedList.map((entry, index) => {
              return (
                <ListItem
                  itemSelected={isSelected(entry.name)}
                  key={index}
                  onClick={() => setFromMainToSelected(entry)}
                >
                  {entry.name}
                </ListItem>
              );
            })}
        </ListContiner>
      </div>
    </TranferOuterWrapper>
  );
};

export default TransferList;

const TranferOuterWrapper = styled.div`
  display: flex;
`;

const ListHeader = styled.div`
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
  background-color: #0e4b8f;
  color: #fff;
`;

const ListContiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 500px;
  overflow-y: scroll;
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 0 0 5px 5px;
`;

const ListItem = styled.p<{ itemSelected: boolean }>`
  margin: 10px 8px 0 8px;
  padding: 1%;
  background-color: ${(props) => (props.itemSelected ? "#25408f" : null)};
  color: ${(props) => (props.itemSelected ? "#fff" : "#000")};

  :hover {
    cursor: pointer;
  }
`;
