const getComponentData = (state) => {
  const { skincarePermissions ,entries ,permissionFeched ,fetching ,error} = state.entries;
  const glowPermissions = skincarePermissions?.find(
    (skincarePermission) => skincarePermission.permissionAreaId === 3
  );
  const view =
    glowPermissions?.permissionLevelId === 2 ||
    glowPermissions?.permissionLevelId === 3 ||
    glowPermissions?.permissionLevelId === 4;
  const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;

  return {
    entries,
    view,
    edit,
    permissionFeched,
    error,
    fetching
  };
};

export default getComponentData;
