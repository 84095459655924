const getComponentData = (state) => {
  const { skincarePermissions } = state.entries;
  const { countries } = state.countries;
  const glowPermissions = skincarePermissions?.find(
    (skincarePermission) => skincarePermission.permissionAreaId === 6
  );
  const view =
    glowPermissions?.permissionLevelId === 2 ||
    glowPermissions?.permissionLevelId === 3 ||
    glowPermissions?.permissionLevelId === 4;
  const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;

  return {
    view,
    edit,
    countries,
    
  };
};

export default getComponentData;
