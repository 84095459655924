import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import DashTable from "./components/DashTable";
import ModalAddAttribute from "./components/ModalAddAttribute";
import ModalAtributeType from "./components/ModalAtributeType";
import SideBar from "./components/SideBar";
import {
  IAttribute,
  IAttributeItem,
  IAttributeType,
  IAttributeTypeItem,
  IType
} from "./DashBoard.interface";
import useQueryForAttributeType from "./hooks/useQueryForAttribute";
import useQueryForSingleAttribute from "./hooks/useQueryForSingleAttribute";

const DashBoard = (): JSX.Element => {
  const [list, setList] = useState<IType[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState(false);
  const [addAttributeModal, setAddAttributeModal] = useState(false);
  const [curAttributeType, setCurAttributeType] = useState<IType>();
  const [curAttribute, setCurAttribute] = useState<IAttribute | null>(null);
  const { addToast } = useToasts();
  const {
    productAttribete,
    getAttribute,
    addAttributeSingle,
    editSingleAttribute,
    refetch,
    deleteSingleAttribute
  } = useQueryForSingleAttribute(curAttributeType);
  const {
    productAttributeTypes,
    addAttributeType,
    deleteAttributeType,
    changeAttributeType,
  } = useQueryForAttributeType(refetch);
  const [attributeType, setAttributeType] =
    useState<IAttributeType>(productAttribete);

  useEffect(() => {
    if (productAttribete) {
      setAttributeType(productAttribete);
      refetch();
    }
  }, [productAttribete]);

  useEffect(() => {
    if (productAttributeTypes) {
      setList(productAttributeTypes);
    }
  }, [productAttributeTypes]);

  const getAttributes = async (type: IType) => {
    try {
      setCurAttributeType(type);
      await getAttribute({
        variables: {
          productAttributeTypeId: type.productAttributeTypeId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const wrapName = (name: string): string => {
    return `{"en": "${name}"}`;
  };

  const handleModal = (state = true) => {
    if (!state) {
      setCurAttribute(null);
    }
    setAddAttributeModal(state);
  };

  const handleSave = (data: IAttributeItem) => {
    const { name, sortOrder } = data;
    const newData = {
      name: wrapName(name.en),
      sortOrder: +sortOrder,
      productAttributeTypeId: curAttributeType?.productAttributeTypeId,
    };
    addAttributeSingle({
      variables: {
        input: newData,
      },
    });
    addToast("Successfully Added Attribute", {
      autoDismiss: true,
      appearance: "success",
    });
    setAddAttributeModal(false);
  };

  const handleType = (data: IAttributeTypeItem | null) => {
    if (data) {
      const newData = {
        input: {
          name: wrapName(data.name.en),
          allowedOn: data.allowedOn && +data.allowedOn,
          backgroundColor: `${data.backgroundColor}`,
          sortOrder: (data.sortOrder && +data.sortOrder) || 0,
          notes: "GraphQL Test - Add ''Specials'' Category",
        },
      };
      addAttributeType({
        variables: {
          ...newData,
        },
      });

      addToast("Successfully Added Attribute Type", {
        autoDismiss: true,
        appearance: "success",
      });
    }
    setModalShow(false);
  };

  const handleAddType = () => {
    setModalShow(true);
    setIsEdit(false);
  };

  const handleEdit = () => {
    setModalShow(true);
    setIsEdit(true);
  };

  const onDeleteAttributeType = (id: number) => {
    deleteAttributeType({
      variables: {
        input: {
          id: id,
        },
      },
    });
    addToast("Successfully Deleted Attribute Type", {
      autoDismiss: true,
      appearance: "success",
    });
  };

  const onDeleteAttribute = (id: number) => {
    setAttributeType((prev) => {
      if (attributeType) {
        const attributes = attributeType?.productAttributes.filter(
          (attribute) => attribute.productAttributeId !== id
        );
        return { ...attributeType, attributes };
      }
      return prev;
    });
    deleteSingleAttribute({
      variables: {
        input: {
          id: id,
        },
      },
    });
    addToast("Successfully Deleted Attribute", {
      autoDismiss: true,
      appearance: "success",
    });
  };

  const onEditAttribute = (attribute: IAttribute) => {
    setCurAttribute(attribute);
    handleModal();
  };

  const onSaveEditAttribute = (attribute: IAttributeItem, attribute2: any) => {
    const newData = {
      name: wrapName(attribute.name.en),
      sortOrder: +attribute.sortOrder,
      productAttributeId: attribute2?.productAttributeId,
    };
    editSingleAttribute({
      variables: {
        input: newData,
      },
    });

    addToast("Successfully Edited Attribute", {
      autoDismiss: true,
      appearance: "success",
    });
    handleModal(false);
  };

  const handleEditAttributeType = (data: IAttributeTypeItem | null) => {
    if (data) {
      const newData = {
        productAttributeTypeId: data.productAttributeTypeId,
        name: wrapName(data.name.en),
        backgroundColor: data.backgroundColor,
        sortOrder: +data.sortOrder,
      };
      changeAttributeType({
        variables: {
          input: newData,
        },
      });
      addToast("Successfully Edited Attribute Type", {
        autoDismiss: true,
        appearance: "success",
      });
    }

    setModalShow(false);
  };

  return (
    <>
      <div className="container">
        <div className="row w-100">
          <div className="col-4">
            <SideBar
              list={list}
              openModal={handleAddType}
              onClickType={getAttributes}
            />
          </div>

          <div className="col-8">
            {attributeType && (
              <DashTable
                attributeType={attributeType}
                onEdit={handleEdit}
                openModal={handleModal}
                onDeleteType={onDeleteAttributeType}
                onDeleteAttribute={onDeleteAttribute}
                onEditAttribute={onEditAttribute}
              />
            )}
          </div>
        </div>
        {addAttributeModal && (
          <ModalAddAttribute
            attribute={curAttribute}
            show={addAttributeModal}
            onHide={() => handleModal(false)}
            onSave={(e: IAttributeItem) => handleSave(e)}
            onSaveEdit={(e: IAttributeItem, attribute: any) =>
              onSaveEditAttribute(e, attribute)
            }
          />
        )}

        {modalShow && (
          <ModalAtributeType
            show={modalShow}
            isEdit={isEdit}
            type={attributeType}
            onHide={(data: IAttributeTypeItem | null) => handleType(data)}
            handleEdit={handleEditAttributeType}
          />
        )}
      </div>
    </>
  );
};

export default DashBoard;
