import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import { clearCacheAsync } from '../../services/enrollmentService'
import { regionCountriesData } from '../../config/regions'
import { keys } from '../../config/keys'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Loader from 'react-loader-spinner'
import Form from 'react-bootstrap/Form'

const CachePage = () => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [countryData, setCountryData] = useState(regionCountriesData)
  const [recacheCountries, setRecacheCountries] = useState([])

  useEffect(() => {
    console.log(recacheCountries)
  }, [recacheCountries])

  const clearCache = async () => {
    setLoading(true)
    if (!recacheCountries.length > 0) {
      setLoading(false)
      addToast(`Please Select Country-s to Clear Cache`, {
        appearance: 'warning',
        autoDismiss: true,
      })
      return
    }

    const res = await clearCacheAsync({ countries: recacheCountries, secretkey: keys.enrollment })
    if (res.status == 200) {
      setLoading(false)
      addToast(`Cleared Cache Successful`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } else {
      setLoading(false)
      addToast(`Error Clearing Cache`, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleToggleCB = (e, region, index, isRegion) => {
    let tempCountryData = { ...countryData }
    let tempCacheArry = [...recacheCountries]

    if (!isRegion) {
      tempCountryData[region][index].selected = !tempCountryData[region][index].selected
      setCountryData(tempCountryData)
      const tupleValues = mapCountries(tempCountryData, tempCacheArry)
      tempCountryData = { ...tupleValues[0] }
      tempCacheArry = [...tupleValues[1]]

      setRecacheCountries(tempCacheArry)
    } else {
      if (e.target.checked === true) {
        tempCountryData[region].forEach((c) => {
          c.selected = true
        })
      } else {
        tempCountryData[region].forEach((c) => {
          c.selected = false
        })
      }
      setCountryData(tempCountryData)
      const tupleValues = mapCountries(tempCountryData, tempCacheArry)
      tempCountryData = { ...tupleValues[0] }
      tempCacheArry = [...tupleValues[1]]

      setRecacheCountries(tempCacheArry)
    }
  }

  const mapCountries = (tempCountryData, tempCacheArry) => {
    Object.keys(tempCountryData).forEach((r) => {
      tempCountryData[r].forEach((c) => {
        if (c.selected && !tempCacheArry.includes(c.countryName)) {
          tempCacheArry.push(c.countryName)
        } else if (!c.selected && tempCacheArry.includes(c.countryName)) {
          const idx = tempCacheArry.indexOf(c.countryName)
          tempCacheArry.splice(idx, 1)
        }
      })
    })

    return [tempCountryData, tempCacheArry]
  }

  return (
    <CachePageWrapper>
      <Row>
        <Col md={12}>
          <h2>Enrollment Cache Refresh</h2>
        </Col>
        <Col md={12}>
          <p className='helper-txt'>
            This page is used to refresh Enrollment as we are currently caching Enrollment Packs on the Kits page to improve the users experince.
          </p>
        </Col>
      </Row>
      <Row className='bottom-section'>
        <Col className='bottom-inner-section' xs={12}>
          <CountriesWrapper>
            {countryData &&
              Object.keys(countryData).map((region, rIndex) => {
                return (
                  <div className='outer-country-wrapper' key={region}>
                    <div className='country-header-wrapper'>
                      <h5>
                        {' '}
                        <Form.Check
                          type={'checkbox'}
                          label={region}
                          id={region}
                          onChange={(e) => {
                            handleToggleCB(e, region, rIndex, true)
                          }}
                        />
                      </h5>
                    </div>
                    <div className='country-selection-wrapper'>
                      {countryData[region].map((country, index) => {
                        return (
                          <div className='country-selection' key={country.id}>
                            <Form.Check
                              type={'checkbox'}
                              label={country.countryName}
                              id={country.countryName}
                              checked={country.selected}
                              onChange={(e) => {
                                handleToggleCB(e, region, index, false)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </CountriesWrapper>
          <Button
            className='btn-margin'
            onClick={(e) => {
              clearCache()
            }}
          >
            Refresh Cache
          </Button>
        </Col>
        {loading && (
          <Col md={12}>
            <Loader type='ThreeDots' color='#0f4b8f' />
            <h4>Clearing Cache...</h4>
          </Col>
        )}
      </Row>
    </CachePageWrapper>
  )
}

export default CachePage

const CachePageWrapper = styled.div`
  margin: 0 1%;
  width: 100%;
  max-width: 1475px;
  width: 100%;

  .bottom-section {
    width: 100%;
    margin: 0 auto;

    .bottom-inner-section {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .outer-country-wrapper {
    width: 360px;
    min-height: 200px;
    border: 2px solid #000;
    margin: 2%;
    display: flex;
    flex-direction: column;

    .country-header-wrapper {
      width: 100%;
      height: 45px;
      border-bottom: 2px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .country-selection-wrapper {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      padding: 1% 3%;

      .country-selection {
        width: 50%;
        display: flex;
        align-items: center;
        padding: 1% 2%;
      }
    }
  }

  .helper-txt {
    color: rgba(0, 0, 0, 0.4);
    font-size: 18px;
  }

  .btn-margin {
    margin: 2%;
    font-weight: 600;
    font-size: 18px;
    width: 275px;
    height: 45px;
    text-transform: uppercase;
    background-color: #0f4b8f;

    :hover {
      box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.3);
    }
  }
`

const CountriesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
`
