const getComponentData = (state) => {
    const { skincarePermissions,accounts,permissionFeched,PermissionsError } = state.entries;
    
    const glowPermissions = skincarePermissions?.find(
      (skincarePermission) => skincarePermission.permissionAreaId === 12
    );
    const view =
      glowPermissions?.permissionLevelId === 2 ||
      glowPermissions?.permissionLevelId === 3 ||
      glowPermissions?.permissionLevelId === 4;
    const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;
  
    return {
      view,
      edit,
      accounts,
      permissionFeched,
      PermissionsError,
    };
  };
  
  export default getComponentData;
  