/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import styled from "styled-components";
import { IAttribute, IAttributeType } from "../DashBoard.interface";

interface IProps {
  attributeType: IAttributeType;
  onEdit: () => void;
  openModal: () => void;
  onDeleteType: (id: number) => void;
  onDeleteAttribute: (id: number) => void;
  onEditAttribute: (attribute: IAttribute) => void;
}

const DashTable = ({
  attributeType,
  openModal,
  onEdit,
  onDeleteType,
  onDeleteAttribute,
  onEditAttribute,
}: IProps): JSX.Element => {
  const [type, setType] = useState<IAttributeType>(attributeType);
  const [showAttrModal, setShowAttrModal] = useState<boolean>(false);
  const [showAttrTypeModal, setShowAttrTypeModal] = useState<boolean>(false);
  const [currentItemToDelete, setCurrentItemToDelete] = useState<any>(null);

  useEffect(() => {
    setType(attributeType);
  }, [attributeType]);

  const sortTable = () => {
    const data = { ...attributeType };
    data.productAttributes.sort((a, b) => a.sortOrder - b.sortOrder);
    setType(() => data);
  };

  const confirmDeleteAttrType = (item: any) => {
    setShowAttrTypeModal(true);
  };

  const handleDeleteAttrType = () => {
    onDeleteType(attributeType.productAttributeTypeId);
    setShowAttrTypeModal(false);
  };

  const confirmDelete = (item: any) => {
    setCurrentItemToDelete(item);
    setShowAttrModal(true);
  };

  const handleDeleteAttribute = (id: number) => {
    setShowAttrModal(false);
    onDeleteAttribute(id);
  };

  const handleEdit = (attribute: IAttribute) => {
    onEditAttribute(attribute);
  };

  const decodeAllowedOn = (allowedOn: number | null | undefined) => {
    let allowedOnText = "";
    switch (allowedOn) {
      case 1:
        allowedOnText = "Product Parent"
        break;
      case 2:
        allowedOnText = "Product"
        break;
      case 3:
        allowedOnText = "Product Variant"
        break;
      default:
        allowedOnText = "Unknown"
    }
    return allowedOnText;
  };

  return (
    <div className="attribute-container w-100 border border-secondary text-left">
      <div className="attribute-header d-flex justify-content-between p-2">
        <div className="p-3">
          <Title>Name: {type.name.en}</Title>
          <Title>Allowed On: {decodeAllowedOn(type.allowedOn)}</Title>
          <Title>Sort: {type.sortOrder}</Title>
          <Title>
            Color:
            <div
              style={{
                backgroundColor: type.backgroundColor,
                width: "60px",
                height: "30px",
                display: "inline-flex",
                position: "relative",
                top: "10px",
                marginLeft: "8px",
              }}
            ></div>
            <div
              style={{
                display: "inline-flex",
                position: "relative",
                top: "0px",
                marginLeft: "8px",
              }}
            >{type.backgroundColor}</div>
          </Title>
        </div>
        <div className="d-flex flex-column p-3">
          <CustomButton variant="primary" className="mb-2" onClick={onEdit}>
            Edit Attribute type
          </CustomButton>
          <Button variant="danger" onClick={confirmDeleteAttrType}>
            Delete Attribute type
          </Button>
        </div>
      </div>
      <CustomButton variant="primary" className="m-3" onClick={openModal}>
        Add Attribute
      </CustomButton>
      <TableWrap className="p-4">
        <Table striped className="text-center">
          <thead>
            <tr>
              <th>Name</th>
              <th onClick={() => sortTable()} style={{ cursor: "pointer" }}>
                Sort
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {type.productAttributes &&
              type.productAttributes.map((item) => (
                <tr key={item.productAttributeId}>
                  <td>{item.name.en}</td>
                  <td>{item.sortOrder}</td>
                  <td>
                    <div className="text-right">
                      <CustomButton
                        variant="primary"
                        className="mr-2"
                        onClick={() => handleEdit(item)}
                      >
                        Edit
                      </CustomButton>
                      <Button
                        variant="danger"
                        onClick={() => confirmDelete(item)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </TableWrap>

      <Modal
        show={showAttrModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ textAlign: "center" }}
          >
            Delete Attribute?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          You are about to delete the attribute{" "}
          <b>{currentItemToDelete && currentItemToDelete.name.en}</b> are you
          sure?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              handleDeleteAttribute(
                currentItemToDelete && currentItemToDelete.productAttributeId
              )
            }
          >
            Continue
          </Button>
          <Button onClick={() => setShowAttrModal(false)} variant="danger">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAttrTypeModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ textAlign: "center" }}
          >
            Delete Attribute Type?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          You are about to delete the attribute type <b>{type.name.en}</b> are
          you sure?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleDeleteAttrType()}>Continue</Button>
          <Button onClick={() => setShowAttrTypeModal(false)} variant="danger">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DashTable;

const Title = styled.p`
  text-align: left;
  font-weight: bold;
`;

const TableWrap = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const CustomButton = styled(Button)`
  background-color: #0f4b8f;
`;
