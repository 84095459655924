const getPermissions = (state) => {
  const { skincarePermissions, permissionFeched, PermissionsError } = state.entries;
  const glowPermissions = skincarePermissions?.find((skincarePermission) => skincarePermission.permissionAreaId === 9);
  // const view =
  //   glowPermissions?.permissionLevelId === 2 ||
  //   glowPermissions?.permissionLevelId === 3 ||
  //   glowPermissions?.permissionLevelId === 4;
  // const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;
  const view = true;
  const edit = true;
  return {
    view,
    edit,
    permissionFeched,
    PermissionsError,
  };
};

export default getPermissions;
