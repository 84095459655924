import React from 'react';
import { Redirect } from 'react-router-dom';
import getComponentData from './CatagoriesSelector';
import { connect } from 'react-redux';
import SpinnerLoader from '../../GlobalComponents/ZilisSpinnerLoader';

const Categories = ({ view, edit }) => {
  return (
    <>
      {
        view ? (
          <div>
            <h2>You are at the Categories Page</h2>
          </div>
        ) : (
          <Redirect to='/NoPermission' />
        )
     }
    </>
  );
};

export default connect(getComponentData)(Categories);
