export const GET_ENTRIES_START = "[ENTRIES] START GET ALL ENTRIES";
export const GET_ENTRIES_SUCCESS = "[ENTRIES] GET ALL ENTRIES SUCCESS";
export const GET_ENTRIES_FAILURE = "[ENTRIES] GET ALL ENTRIES FAILURE";
export const GET_ACCOUNTS_START = "[ACCOUNTS] START GET ALL ACCOUNTS";
export const GET_ACCOUNTS_SUCCESS = "[ACCOUNTS] GET ALL ACCOUNTS SUCCESS";
export const GET_ACCOUNTS_FAILURE = "[ACCOUNTS] GET ALL ACCOUNTS FAILURE";
export const GET_FILTERED_ACCOUNTS_START = "[ACCOUNTS] START GET FILTERED ACCOUNTS";
export const GET_FILTERED_ACCOUNTS_SUCCESS = "[ACCOUNTS] GET FILTERED ACCOUNTS SUCCESS";
export const GET_FILTERED_ACCOUNTS_FAILURE = "[ACCOUNTS] GET FILTERED ACCOUNTS FAILURE";
export const LOGIN_ADMIN_SKINCARE_START = "[SKINCARE] LOGIN ADMIN START";
export const LOGIN_ADMIN_SKINCARE_SUCCESS = "[SKINCARE] LOGIN ADMIN SUCCESS";
export const LOGIN_ADMIN_SKINCARE_FAILURE = "[SKINCARE] LOGIN ADMIN FAILURE";
export const PERMISSIONS_SKINCARE_START= "PERMISSIONS_SKINCARE_START";
export const PERMISSIONS_SKINCARE_SUCCESS= "PERMISSIONS_SKINCARE_SUCCESS";
export const PERMISSIONS_SKINCARE_FAILURE = "PERMISSIONS_SKINCARE_FAILURE";
