import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IChipProps {
  label: string
  color: string // hex-code
  size?: 'SMALL' | 'LARGE' | 'XL'
}
interface IChipConfig {
  paddingTopBottom: number
  paddingLeftRight: number
  fontSize: number
}

const Chip = ({ label, color, size = 'SMALL' }: IChipProps) => {
  const [_color, setColor] = useState(color)
  const initChipConfig: IChipConfig = {
    paddingTopBottom: 0,
    paddingLeftRight: 8,
    fontSize: 12,
  }
  const [chipConfig, setChipConfig] = useState<IChipConfig>(initChipConfig)

  useEffect(() => {
    setChipConfig(handleChipConfig(size))
    if (color === '#fff' || color === '#FFF' || color === '#ffffff' || color === '#FFFFFF') {
      setColor('dodgerblue')
    }
  }, [size, color])

  const handleChipConfig = (size: 'SMALL' | 'LARGE' | 'XL'): IChipConfig => {
    switch (size) {
      case 'SMALL':
        return {
          paddingTopBottom: 0,
          paddingLeftRight: 8,
          fontSize: 12,
        }
      case 'LARGE':
        return {
          paddingTopBottom: 2,
          paddingLeftRight: 5,
          fontSize: 16,
        }
      case 'XL':
        return {
          paddingTopBottom: 5,
          paddingLeftRight: 8,
          fontSize: 18,
        }
      default:
        return {
          paddingTopBottom: 0,
          paddingLeftRight: 0,
          fontSize: 12,
        }
    }
  }

  return (
    <ChipContainer color={_color} chipConfig={chipConfig}>
      <p className='label-text'>{label}</p>
    </ChipContainer>
  )
}

export default Chip

const ChipContainer = styled.div<{ color: string; chipConfig: IChipConfig }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  width: fit-content;
  min-height: 20px;
  padding: ${(props) => props.chipConfig.paddingTopBottom + 'px' + ' ' + props.chipConfig.paddingLeftRight + 'px'};
  border-radius: 15px;
  background-color: ${(props) => (props.color ? props.color : 'dodgerblue')};

  .label-text {
    font-size: ${(props) => props.chipConfig.fontSize}px;
    color: #fff;
    margin: 0;
  }
`
