export const regionCountriesData = {
  USA: [
    {
      id: 1,
      countryName: 'USA',
      countryCode: '',
      selected: false,
      region: 'USA',
    },
  ],
  LATAM: [
    {
      id: 2,
      countryName: 'COLOMBIA',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
    {
      id: 3,
      countryName: 'PERU',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
    {
      id: 4,
      countryName: 'ECUADOR',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
    {
      id: 5,
      countryName: 'COSTA RICA',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
    {
      id: 6,
      countryName: 'GUATEMALA',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
    {
      id: 7,
      countryName: 'PANAMA',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
    {
      id: 8,
      countryName: 'MEXICO',
      countryCode: '',
      selected: false,
      region: 'LATAM',
    },
  ],
  EU: [
    {
      id: 10,
      countryName: 'AUSTRIA',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 11,
      countryName: 'BELGIUM',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 12,
      countryName: 'CROATIA',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 13,
      countryName: 'CZECH REPUBLIC',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 14,
      countryName: 'DENMARK',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 15,
      countryName: 'ESTONIA',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 16,
      countryName: 'GERMANY',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 17,
      countryName: 'GREECE',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 18,
      countryName: 'HUNGARY',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 19,
      countryName: 'ICELAND',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 20,
      countryName: 'ITALY',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 21,
      countryName: 'LATVIA',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 22,
      countryName: 'LIECHTENSTEIN',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 23,
      countryName: 'LITHUANIA',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 24,
      countryName: 'NETHERLANDS',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 25,
      countryName: 'POLAND',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 26,
      countryName: 'SLOVENIA',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 27,
      countryName: 'SPAIN',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 28,
      countryName: 'SWEDEN',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 29,
      countryName: 'UNITED KINGDOM',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
    {
      id: 30,
      countryName: 'SWITZERLAND',
      countryCode: '',
      selected: false,
      region: 'EU',
    },
  ],
}
