const getComponentData = (state) => {
  const { skincarePermissions,accounts,permissionFeched,PermissionsError ,error} = state.entries;
  const userPermissions = skincarePermissions?.find(
    (skincarePermission) => skincarePermission.permissionAreaId === 14
  );

  const view = userPermissions?.permissionLevelId === 2 || userPermissions?.permissionLevelId === 3 || userPermissions?.permissionLevelId === 4;
  const edit = userPermissions?.permissionLevelId === 3 || userPermissions?.permissionLevelId === 4;

  return {
    view,
    edit,
    accounts,
    permissionFeched,
    error,
    PermissionsError
  };
};

export default getComponentData;
