/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react'
import { ProductStore } from '../component-state/productDetailState'
import * as PDA from '../component-state/productDetailActions'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import { Row, Col, Button } from 'react-bootstrap'
import TransferList from '../../TansferList'

interface IAttributes {
  inputList: Array<any>
  existingProductAttributes: any
  setUpdateAttributes: any
  handleSaveAttributes: any
  setResetAttributes: (val: boolean) => void
}

const Attributes = ({ inputList, existingProductAttributes, setUpdateAttributes, handleSaveAttributes, setResetAttributes }: IAttributes) => {
  const { addToast } = useToasts()
  const { state, dispatch } = useContext(ProductStore)
  const [existing, setExisting] = useState<any>()
  const [reset, setReset] = useState(false)

  useEffect(() => {
    setExisting(existingProductAttributes)
  }, [existingProductAttributes])

  const handleSelection = (e: any) => {
    const value = Number(e.target.value)
    dispatch({
      type: PDA.UPDATE_ATTRIBUTE_SELECTION,
      payload: inputList.find((attr: any) => {
        return attr.productAttributeTypeId === value
      }),
    })
  }

  const showUndoMesage = () => {
    addToast(`Attribute Changes Cancelled.`, {
      appearance: 'success',
      autoDismiss: true,
    })
  }

  return (
    <TabPageWrapper>
      <Row style={{ paddingTop: '2%' }}>
        <Button className='attr-save-btn' onClick={handleSaveAttributes}>
          Save
        </Button>
        <Button
          className='attr-save-btn cancel-btn'
          onClick={() => {
            setResetAttributes(true)
            setReset(true)
            showUndoMesage()
          }}
        >
          Cancel
        </Button>
        <Col className='group-attr-wrapper' lg={12}>
          <div className='selection-attr-wrapper'>
            <select className='product-attr-selection' onChange={(e) => handleSelection(e)}>
              <option disabled selected>
                Attribute Selection
              </option>
              {inputList &&
                inputList.length > 0 &&
                inputList.map((choice: any) => {
                  return (
                    <option key={choice.productAttributeTypeId} value={choice.productAttributeTypeId}>
                      {choice.name.en}
                    </option>
                  )
                })}{' '}
              *
            </select>

            <TransferList
              mainList={(state && state.attributes.selectedOption && state.attributes.selectedOption.productAttributes) || []}
              existingProductAttributes={existing}
              setUpdateAttributes={setUpdateAttributes}
              reset={reset}
              setReset={setReset}
            />
          </div>
        </Col>
      </Row>
    </TabPageWrapper>
  )
}

export default Attributes

const TabPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2%;

  .attr-save-btn {
    width: 175px;
    height: 45px;
    margin: 0 2% 0 0;
    background-color: #0e4b8f;
    font-size: 22px;
    margin-bottom: 2%;
  }

  .cancel-btn {
    background-color: #dc3545;
    border: none;
  }

  .group-attr-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    .selection-attr-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0;

      .product-attr-selection {
        width: 300px;
        height: 50px;
      }
    }
  }
`
