import gql from "graphql-tag";

export const ADD_DOCUMENT = gql`
	mutation addCoaDocument($input: AddCoaDocumentInput!) {
		addCoaDocument(input: $input) {
			coaDocumentId
			name
			uploadedOn
			fileUrl
			isApproved
			isArchived
			notes
		}
	}
`;

export const EDIT_DOCUMENT = gql`
	mutation changeCoaDocument($input: ChangeCoaDocumentInput!) {
		changeCoaDocument(input: $input) {
			coaDocumentId
			name
			uploadedOn
			fileUrl
			isApproved
			isArchived
			notes
		}
	}
`;

export const UPDATE_DOCUMENT_SORT_ORDER = gql`
	mutation changeCoaDocuments(
		$coaProductId: Int!
		$coaDocuments: [ChangeCoaDocumentInput!]!
	) {
		changeCoaDocuments(coaProductId: $coaProductId, coaDocuments: $coaDocuments) {
			coaDocumentId
			sortOrder
		}
	}`;

export const REMOVE_COA_DOCUMENT = gql`
	mutation removeCoaDocument($input: RemoveInput!) {
		removeCoaDocument(input: $input) {
			success
		}
	}
`;

export const ADD_PRODUCT = gql`
	mutation addCoaProduct($input: AddCoaProductInput!) {
		addCoaProduct(input: $input) {
			coaProductId
			name
			displayName
			lastUpdatedOn
			sortOrder
			coaSection {
				coaSectionId
				name
			}
			countryRegion {
				countryRegionId
				name: countryRegionKey
			}
		}
	}
`;

export const EDIT_PRODUCT = gql`
	mutation changeCoaProduct($input: ChangeCoaProductInput!) {
		changeCoaProduct(input: $input) {
			coaProductId
			name
			displayName
			lastUpdatedOn
			sortOrder
			lastUpdatedOn
			coaSectionId
			coaSection {
				coaSectionId
				name
			}
			countryRegionId
			countryRegion {
				countryRegionId
				name: countryRegionKey
			}
		}
	}
`;

export const REMOVE_PRODUCT = gql`
	mutation removeCoaProduct($input: RemoveInput!) {
		removeCoaProduct(input: $input) {
			success
		}
	}
`;

export const REMOVE_USER = gql`
  mutation removeUser($userId: Int!) {
    removeUser(input: { userId: $userId }) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation changeUser($userId: Int!, $name: String!, $email: String!,$userPermissions:[PermissionInput!]) {
    changeUser(input: { userId: $userId, name: $name, email: $email, userPermissions: $userPermissions }) {
      user {
        id
        name
        email
        userPermissions {
          id
          permissionAreaId
          permissionAreaName
          permissionLevelId
          permissionLevelName
        }
      }
    }
  }
`;

export const ADD_NEW_USER = gql`
mutation addUser($input: AddUserInput!) {
  addUser(input: $input) {
        user {
            id
            name
            email
            userPermissions {
                id
                permissionAreaId
                permissionAreaName
                permissionLevelId
                permissionLevelName
            }
        }
    }
}
`;
