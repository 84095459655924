import moment from 'moment/moment'

export function formatDateOnly(date: any, excludeDefaults = true) {
  let dateOnly = ''

  if (date) {
    const last = date.indexOf('Z')
    dateOnly = last >= 0 ? date.substring(0, last) : date
    dateOnly = moment(dateOnly).format('MM/DD/YYYY')

    if (excludeDefaults && (dateOnly === '01/01/0001' || dateOnly === '12/31/9999')) {
      dateOnly = ''
    }
  }

  return dateOnly
}

/**
 *
 * @param date e.g. "2023-04-06T00:00:00.000Z"
 * @returns string e.g. "April 05, 2023"
 */
export function formatSystemDateToViewDate(systemDate: string): string {
  const date = moment.utc(systemDate).format('MMMM DD, YYYY')
  return date.toString()
}

/**
 *
 * @param dateInput string
 * @returns string e.g. "2023-04-06T00:00:00.000Z"
 */

export function formatDateToServiceReq(dateInput: string): string {
  const date = moment(dateInput).toISOString()
  return date
}
