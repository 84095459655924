/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { IAttributeType, IAttributeTypeItem } from '../DashBoard.interface'

interface IProps {
  isEdit: boolean
  show: boolean
  type?: IAttributeType
  onHide: (data: IAttributeTypeItem | null) => void
  handleEdit: (data: IAttributeTypeItem | null) => void
}

const defaultFormValue: IAttributeTypeItem = {
  name: { en: '' },
  sortOrder: 0,
  backgroundColor: '#000',
  allowedOn: null,
}

const ModalAtributeType = ({ isEdit, show, type, onHide, handleEdit }: IProps): JSX.Element => {
  const [formValue, setFormValue] = useState<IAttributeTypeItem>(defaultFormValue)
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)
  const [formValid, setFormValid] = useState<boolean>(false)

  const colorBox = {
    backgroundColor: formValue.backgroundColor,
  }

  const handleChange = (e: any) => {
    let { value } = e.target
    const { name } = e.target
    if (name === 'name') {
      value = { en: value }
    }
    setFormValue({ ...formValue, [name]: value })
  }

  const onHideModal = (): void => {
    onHide(null)
    reset()
  }

  const onSubmit = (): void => {
    isEdit ? handleEdit(formValue) : onHide(formValue)
    reset()
  }

  const toggleDisplayColorPicker = (): void => {
    setDisplayColorPicker(!displayColorPicker)
  }

  useEffect(() => {
    if (isEdit && type) {
      console.log('type', type)
      const { name, sortOrder, backgroundColor, productAttributeTypeId } = type
      setFormValue({
        name,
        sortOrder,
        backgroundColor,
        productAttributeTypeId,
      })
    }
  }, [])

  const reset = (): void => {
    setFormValue(defaultFormValue)
    setDisplayColorPicker(false)
  }

  useEffect(() => {
    if (formValue.name.en !== '' && formValue.sortOrder !== null && formValue.sortOrder !== undefined) {
      if (!isEdit && formValue.allowedOn !== null) {
        setFormValid(true)
      } else if (isEdit) {
        setFormValid(true)
      }
    } else {
      setFormValid(false)
    }
  }, [formValue])

  return (
    <Modal show={show} onHide={onHideModal} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Attribute Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm='2'>
              Name
            </Form.Label>
            <Col sm='10'>
              <Form.Control type='text' value={formValue.name.en} name='name' onChange={handleChange} isInvalid={!formValid} />
              <Form.Control.Feedback type='invalid'>Please choose valid name.</Form.Control.Feedback>
            </Col>
          </Form.Group>
          {!isEdit && (
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm='2'>
                Allowed On
              </Form.Label>
              <Col sm='10'>
                <select
                  className='form-select text-left w-100 p-1'
                  aria-label='Default select example'
                  name='allowedOn'
                  onChange={handleChange}
                  required
                >
                  <option selected>Select Allowed On</option>
                  <option value='1'>Product Parent</option>
                  <option value='2'>Product</option>
                  <option value='3'>Product Variant</option>
                </select>
                <Form.Control.Feedback type='invalid'>Please choose Allowed On option.</Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm='2'>
              Sort
            </Form.Label>

            <Col sm='10'>
              <Form.Control type='number' value={formValue.sortOrder} name='sortOrder' onChange={handleChange} isInvalid={!formValid} />
              <Form.Control.Feedback type='invalid'>Please choose a sort number.</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column sm='2'>
              Color
            </Form.Label>

            <Col sm='10'>
              <StyledDiv>
                <ColorPicker onClick={() => toggleDisplayColorPicker()} style={colorBox}></ColorPicker>
                {displayColorPicker && (
                  <SketchPicker
                    disableAlpha={true}
                    presetColors={[]}
                    color={formValue.backgroundColor}
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'backgroundColor', value: e.hex },
                      })
                    }
                  />
                )}
              </StyledDiv>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSubmit()} disabled={!formValid}>
          Save
        </Button>
        <Button onClick={() => onHideModal()}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAtributeType

const ColorPicker = styled.div`
  width: 70px;
  height: 30px;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  cursor: pointer;
`

const StyledDiv = styled.div`
  .flexbox-fix {
    & > div:not(:first-child) {
      display: none;
    }
  }
`
