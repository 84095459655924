import gql from "graphql-tag";

export const GET_PRODUCTS = gql`
  query {
    coaProducts(order: [
      { sortOrder: ASC },
      { name: ASC }
    ]) {
      coaProductId
      coaSectionId
      countryRegionId
      name
			displayName
      lastUpdatedOn
      coaSection {
        coaSectionId
        name
      }
      countryRegion {
        countryRegionId
        name: countryRegionKey
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query getCoaProduct($coaProductId: Int!) {
    coaProducts(where: { coaProductId: { eq: $coaProductId } }) {
      coaProductId
      name
      displayName
      lastUpdatedOn
      sortOrder
      coaDocuments {
        coaDocumentId
        name
        uploadedOn
        fileUrl
        isApproved
        isArchived
      }
      coaSection {
        coaSectionId
        name
      }
      countryRegion {
        countryRegionId
        name: countryRegionKey
      }
    }
  }
`;

export const GET_DOCUMENTS_BY_PRODUCT_ID = gql`
  query getCoaDocuments($coaProductId: Int!) {
    coaDocuments(
      where: { coaProductId: { eq: $coaProductId } }
      order: { sortOrder: ASC }
    ) {
      coaDocumentId
      name
      uploadedOn
      fileUrl
      isApproved
      isArchived
      sortOrder
      notes
    }
  }
`;

export const GET_DOCUMENT_BY_ID = gql`
  query getCoaDocument($coaDocumentId: Int!) {
    coaDocuments(
      where: { coaDocumentId: { eq: $coaDocumentId } }
    ) {
      coaDocumentId
      coaProductId
      name
      uploadedOn
      fileUrl
      isApproved
      isArchived
      sortOrder
      notes
    }
  }
`;

export const GET_SECTIONS = gql`
	query {
		coaSections(order: { sortOrder: ASC }) {
			coaSectionId
			name
		}
	}
`;

export const GET_USERS = gql`
query {
users(order: { name: ASC }) {
id
name
email
createdBy
createdDate
modifiedBy
modifiedDate
lastLogin
}
}`;

export const GET_PERMISSIONS = gql`
query UserId($id : Int!){
  userPermissions(where: { userId: { eq: $id } }) {
  id
  permissionAreaId
  permissionAreaName
  permissionLevelId
  permissionLevelName
  }
  }
`;

export const GET_PERMISSIONS_BY_EMAIL = gql`
query permissions($email: String!){
  users(where: { email: { eq: $email } }) {
      id
      name
      email
      userPermissions {
          id
          permissionAreaId
          permissionAreaName
          permissionLevelId
          permissionLevelName
      }
  }
}
`;
