/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useReducer } from 'react'
import * as PDA from './productActions'

interface IInitalState {
  loading: boolean
  error: any
  products: any
  attributes: {
    updateRegistred: boolean
    selectedOption: Record<string, unknown>
    attributesList: any
    selectedList: any
  }
  media: any
}

export const intialState: IInitalState = {
  loading: false,
  error: null,
  products: {},
  attributes: {
    updateRegistred: false,
    selectedOption: {},
    attributesList: [],
    selectedList: {},
  },
  media: {},
}

export const productReducer = (state: IInitalState, action: { type: string; payload: any }) => {
  console.log(action)
  switch (action.type) {
    case PDA.START_LOADING:
      return {
        ...state,
        loading: true,
      }
    case PDA.UPDATE_ATTRIBUTE_SELECTION:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          updateRegistred: true,
          selectedOption: { ...action.payload },
        },
      }
    default:
      return state
  }
}

export const ProductStore = createContext<{ state: IInitalState; dispatch: (action: any) => void }>({
  state: intialState,
  dispatch: (action: any) => {},
})

export const ProductProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(productReducer, intialState)
  const value = { state, dispatch }
  return <ProductStore.Provider value={value as any}>{children}</ProductStore.Provider>
}
