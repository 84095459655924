const environment = process.env.REACT_APP_ENV || 'development'

const handleSetProductsURL = () => {
  switch (environment) {
    case 'development':
      return 'https://productconfigurationapi-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://productconfigurationapi-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://productconfigurationapi-zilis.azurewebsites.net'
    default:
      return 'https://productconfigurationapi-test-zilis.azurewebsites.net'
  }
}

const handleSkinCareEnvURL = () => {
  switch (environment) {
    case 'development':
      return 'https://challengebe-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://challengebe-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://challengebe-zilis.azurewebsites.net'
    default:
      return 'https://challengebe-test-zilis.azurewebsites.net'
  }
}

const handleAdminPermissions = () => {
  switch (environment) {
    case 'development':
      return 'https://identityapi-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://identityapi-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://identityapi-zilis.azurewebsites.net'
    default:
      return 'https://identityapi-test-zilis.azurewebsites.net'
  }
}

const handleSCTargetURL = () => {
  switch (environment) {
    case 'development':
      return 'https://challengebe-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://challengebe-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://challengebe-zilis.azurewebsites.net'
    default:
      return 'https://challengebe-test-zilis.azurewebsites.net'
  }
}

const handleAdminAPIURL = () => {
  switch (environment) {
    case 'development':
      return 'https://adminapi-test-zilis.azurewebsites.net/graphql'
      //return 'https://localhost:5012/graphql'
    case 'test':
      return 'https://adminapi-test-zilis.azurewebsites.net/graphql'
    case 'production':
      return 'https://adminapi-zilis.azurewebsites.net/graphql'
    default:
      return 'https://adminapi-test-zilis.azurewebsites.net/graphql'
  }
}

const handleEnrollmentAPIURL = () => {
  switch (environment) {
    case 'development':
      return 'https://enrollmentapp-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://enrollmentapp-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://join.zilis.com'
    default:
      return 'https://enrollmentapp-test-zilis.azurewebsites.net'
  }
}

const skincareUser = {
  username: 'AdminApp',
  password: 'vHIsAQ7FWR1tDz',
}

const config = {
  PRODUCTSBASEURL: handleSetProductsURL(),
  SKINCAREBASEURL: handleSkinCareEnvURL(),
  SCTARGETURL: handleSCTargetURL(),
  ADMINEPERMISSIONS: handleAdminPermissions(),
  ADMINAPIURL: handleAdminAPIURL(),
  SKINCAREUSER: skincareUser,
  ENROLLMENTURL: handleEnrollmentAPIURL(),
}

export default config
