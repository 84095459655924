/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import Pagination from '../../../SkincareChallenge/SCEntryList/Pagination'
import { CaretUp, CaretDown } from 'react-bootstrap-icons'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SpinnerLoader from '../../../GlobalComponents/ZilisSpinnerLoader'
import { useToasts } from 'react-toast-notifications'
import { GET_PARENT_PRODUCTS } from '../../../utils/shopping/Shopping.Product.Queries'
import { useQuery } from '@apollo/react-hooks'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ZilisLoader from '../../../GlobalComponents/ZilisLoader'
import Chip from '../../../GlobalComponents/Chip'

const ParentProductsList = () => {
  const [entries, setEntries] = useState<Array<any>>([])
  const [filteredEnries, setFilteredEntries] = useState<Array<any>>([])
  const [pageNo, setPageNo] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [colSort, setColSort] = useState('entries.id')
  const [sortDirection, setSortDirection] = useState('asc')
  const [nameFilter, setNameFilter] = useState<string>('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { loading, data, refetch, error } = useQuery(GET_PARENT_PRODUCTS, {
    variables: { skip: (pageNo - 1) * perPage, take: perPage },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    data && setEntries(data.productParents.items)
  }, [data])

  useEffect(() => {
    entries.length > 0 && setFilteredEntries(entries)
  }, [entries])

  const filterRecords = (target: any) => {
    const id = target.id
    const value = target.value
    switch (id) {
      case 'nameFilter':
        {
          setNameFilter(value)
          filterRecordsByName(value)
        }
        break
      default:
        null
    }
  }

  const filterRecordsByName = (value: string) => {
    let filtered: Array<any> = []
    if (value) {
      filtered = entries.filter((record) => {
        if (fuzzySearch(record.inventory.name, value)) return record
      })
    } else {
      filtered = [...entries]
    }
    setFilteredEntries(filtered)
  }

  const fuzzySearch = function (searchVal: string, query: string) {
    const str = searchVal.toLowerCase()
    let i = 0,
      n = -1,
      l
    query = query.toLowerCase()
    for (; (l = query[i++]); ) {
      if (!~(n = str.indexOf(l, n + 1))) {
        return false
      }
    }
    return true
  }

  const entriesSort = (numPerPage: any, pageNoVal: any, sortInfo: any, sortBy: any) => {
    setColSort(sortInfo)
    setSortDirection(sortBy)
  }

  const updatePerPage = (val: any) => {
    setPerPage(Number(val))
  }

  const updatePageNo = (val: any) => {
    setPageNo(Number(val))
  }

  return (
    <MainContainer>
      <h1 className='page-title'>Parent Products</h1>
      {true ? (
        <>
          <EntryTable>
            {/*<section className='filter-row'>*/}
            {/*  <input*/}
            {/*    className='filter-input'*/}
            {/*    placeholder='Filter by Name'*/}
            {/*    value={nameFilter}*/}
            {/*    id='nameFilter'*/}
            {/*    onChange={(e: any) => {*/}
            {/*      filterRecords(e.target)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</section>*/}
            {loading && (
              <LoadingConainer>
                <ZilisLoader /> <h3>Loading Parent Products...</h3>
              </LoadingConainer>
            )}
            <table>
              <thead>
                <tr>
                  <th className='head'>
                    Name <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.id', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.id', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    Products
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    Attributes
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    Active
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredEnries.length > 0 &&
                  filteredEnries.map((entry, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{entry.inventory.name}</td>
                        <td>{entry.productCount}</td>
                        <td className='attr-td'>
                          {entry.productAttributes.map((attr: any, index: number) => {
                            return (
                              <div key={index} className='attr-chip-wrapper'>
                                <Chip label={attr.name.en} color={attr.productAttributeType.backgroundColor} />
                              </div>
                            )
                          })}
                        </td>
                        <td>{entry.inventory.active === 1 ? 'Active' : 'Not Active'}</td>
                        <td>
                          <Link to={`parent-product-detail/${entry.productParentId}`}>Edit</Link>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </EntryTable>

          <Pagination getEntries={[]} updatePerPage={updatePerPage} updatePageNo={updatePageNo} />
        </>
      ) : (
        <Redirect to='/NoPermission' />
      )}
    </MainContainer>
  )
}

export default ParentProductsList

const MainContainer = styled.div`
  width: 100%;
  max-width: 1475px;

  .page-title {
    text-align: left;
  }
`

const EntryTable = styled.div`
  width: 100%;
  padding: 1px;
  margin: 0;

  table {
    width: 100%;
    th {
      text-align: left;
    }

    tr {
      &:nth-child(even) {
        background: #d0d1d1;
      }

      td {
        padding: 5px 0;
        text-align: left;
      }
    }

    .attr-td {
      display: flex;
      flex-flow: row wrap;
      max-width: 400px;
    }
  }

  .head {
    font-size: 18px;
    font-weight: 400;
    color: rgb(94, 93, 93);
    margin: 1px 1vw;
    border-bottom: 1px solid #094a8a;
  }

  .button-row {
    display: flex;
    align-items: flex-end;
    width: 100%;
    .add-entry-btn {
      width: 200px;
      height: 40px;
    }
  }

  .filter-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2%;

    .filter-input {
      border: 1px solid #0f4b8f;
      width: 275px;
      height: 40px;
      padding-left: 8px;
    }
  }

  .table-action-link {
    color: dodgerblue;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .attr-chip-wrapper {
    margin: 1px 2px;
  }
`
const LoadingConainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
