import React, { useState, useEffect } from 'react';
import Pagination from '../GlobalComponents/Pagination';
import config from '../config/env-urls';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/fontawesome-free-solid';
import SpinnerLoader from '../GlobalComponents/ZilisSpinnerLoader';
import getComponentData from './UserPermissionSelector';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ConfirmDel from './ConfirmDel';
import { GET_USERS } from '../utils/GQLqueries';

const UserAuthorizationStatusTable = (props) => {
  const [showDel, setShowDel] = useState('hide');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [message, setMessage] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [blank, setBlank] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sortType, setSortType] = useState('name');
  const [sortDirectionAsc, setSortDirectionAsc] = useState(false);
  const [id, setId] = useState([]);
  const [userName, setUserName] = useState([]);
  // const [sortByName, setSortByName] = useState(true);
  // const [sortByEmail, setSortByEmail] = useState(false);
  // const [sortByLastAccessed, setSortByLastAccessed] = useState(false);
  // const [sortTypeName, setSortTypeName] = useState("asc");
  // const [sortTypeEmail, setSortTypeEmail] = useState("asc");
  // const [sortTypeLastAccessed, setSortTypeLastAccessed] = useState("asc");
  const { view, edit } = props;
  const { addToast } = useToasts();
  const pageOptions = [10, 15, 20];
  const { error, data, loading, refetch } = useQuery(GET_USERS);

  const filterAndSort = () => {
    if (data?.users) {
      let currentUsers = [...data.users];

      if (name === '' && email === '') {
        setFilteredUsers(currentUsers);
      }

      if (email.length > 0) {
        currentUsers = currentUsers.filter((user) => user.email.toUpperCase().includes(email.toUpperCase()));
      }

      if (name.length > 0) {
        currentUsers = currentUsers.filter((user) => user.name.toUpperCase().includes(name.toUpperCase()));
      }

      currentUsers = currentUsers.sort((a, b) => {
        if (!a[sortType]) return 1;
        if (!b[sortType]) return -1;
        if (typeof a[sortType] === 'string') {
          return sortDirectionAsc ? a[sortType].localeCompare(b[sortType]) : b[sortType].localeCompare(a[sortType]);
        }
        const direction = sortDirectionAsc ? -1 : 1;

        return a[sortType] > b[sortType] ? -1 * direction : 1 * direction;
      });
      setFilteredUsers(currentUsers);
    }
  };

  const handleSort = (type) => {
    if (type === sortType) {
      setSortDirectionAsc(!sortDirectionAsc);
    } else {
      setSortDirectionAsc(false);
    }
    setSortType(type);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      addToast(`The information failed to load. Please refresh the page. Contact IT if the problem continues.`, {
        appearance: 'error',
      });
    } else {
      filterAndSort();
    }
  }, [name, email, sortType, sortDirectionAsc, data, error]);

  const onDelete = (id) => {
    let newFilteredUsers = [...filteredUsers];
    newFilteredUsers = newFilteredUsers.filter((newFilteredUser) => newFilteredUser.id !== id);
    setFilteredUsers(newFilteredUsers);
  };

  const showDelete = (id, name) => {
    setId(id);
    setUserName(name);
    showDel === 'hide' ? setShowDel('show') : setShowDel('hide');
  };

  return (
    <Wrapper>
      <div>
        <UserTitleContainer>
          <Title>User Accounts</Title>
          {edit && (
            <Link
              to={{
                pathname: `/Settings/users/add`,
              }}
            >
              <StyledButton>Add User</StyledButton>
            </Link>
          )}
        </UserTitleContainer>
      </div>
      {!loading ? (
        view ? (
          <>
            <PermissionTable>
              <table>
                <thead>
                  <tr>
                    <th>
                      <StyledInput
                        placeholder='First - Last Name'
                        style={{
                          width: '229px',
                          marginBottom: '15px',
                        }}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </th>
                    <th>
                      <StyledInput
                        placeholder='Email'
                        style={{
                          width: '271px',
                          marginBottom: '15px',
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th onClick={() => handleSort('name')} className='head'>
                      First - Last Name
                      <span style={{ verticalAlign: 'middle' }}>
                        {sortType === 'name' && (
                          <FontAwesomeIcon
                            style={{
                              marginLeft: '15px',
                              cursor: 'pointer',
                              color: '#0F4B8F',
                            }}
                            icon={sortDirectionAsc ? faChevronUp : faChevronDown}
                          />
                        )}
                      </span>
                      <span
                        style={{
                          verticalAlign: 'middle',
                        }}
                      ></span>
                    </th>
                    <th onClick={() => handleSort('email')} className='head'>
                      Email
                      <span style={{ verticalAlign: 'middle' }}>
                        {sortType === 'email' && (
                          <FontAwesomeIcon
                            style={{
                              marginLeft: '15px',
                              cursor: 'pointer',
                              color: '#0F4B8F',
                            }}
                            icon={sortDirectionAsc ? faChevronUp : faChevronDown}
                          />
                        )}
                      </span>
                      <span
                        style={{
                          verticalAlign: 'middle',
                        }}
                      ></span>
                    </th>
                    <th className='head' onClick={() => handleSort('lastLoginDate')}>
                      Last Accessed
                      <span style={{ verticalAlign: 'middle' }}>
                        {sortType === 'lastLoginDate' && (
                          <FontAwesomeIcon
                            style={{
                              marginLeft: '15px',
                              cursor: 'pointer',
                              color: '#0F4B8F',
                            }}
                            icon={sortDirectionAsc ? faChevronUp : faChevronDown}
                          />
                        )}
                      </span>
                      <span
                        style={{
                          verticalAlign: 'middle',
                        }}
                      ></span>
                    </th>
                    {edit && <th className='head'>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers?.map((user, i) => {
                    return (
                      <tr key={i} id='row'>
                        <td>{user.name}</td>
                        <td>
                          {user.email}
                          {blank}
                        </td>
                        <td>{user.lastLoginDate && moment(user.lastLoginDate).format()}</td>
                        {edit && (
                          <td>
                            <Link
                              to={{
                                pathname: `/Settings/users/edit/${user.id}`,
                                state: user,
                              }}
                            >
                              <button id='edit'>Edit</button>
                            </Link>
                            |
                            <button id='edit' onClick={() => showDelete(user.id, user.name)}>
                              Delete
                            </button>
                            <Delete>
                              <div className={showDel}>
                                <ConfirmDel
                                  product={props.product}
                                  id={id}
                                  onDelete={onDelete}
                                  type={'User'}
                                  fetch={props.fetch}
                                  show={showDelete}
                                  name={userName}
                                />
                              </div>
                            </Delete>
                            <Overlay showDel={showDel} />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </PermissionTable>
          </>
        ) : (
          <Redirect to='/NoPermission' />
        )
      ) : (
        <tr id='row'>
          <td colSpan='5'>
            <SpinnerLoader />
          </td>
        </tr>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1475px;
  width: 100%;
`;

const PermissionTable = styled.div`
  padding: 1px;
  margin: 0;

  table {
    width: 100%;
    text-align: left;
    tr {
      &:nth-child(even) {
        background: #f4fafe;
      }

      td {
        padding: 5px 0;
      }
    }
  }

  .head {
    font-size: 18px;
    font-weight: 400;
    color: rgb(94, 93, 93);
    user-select: none;
    cursor: pointer;
    border-bottom: 1px solid #094a8a;
    background-color: #ffffff;
    margin-right: 50px;
    width: 100px;
  }
`;

const StyledInput = styled.input`
  border: 1px solid #0f4b8f;
  width: 271px;
`;

const UserTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.p`
  color: #707070;
  font-size: 36px;
  text-align: left;
`;

const StyledButton = styled.button`
  background-color: #0f4b8f;
  color: white;
  font-weight: bold;
  border: none;
  height: 36px;
  padding-left: 14px;
  padding-right: 14px;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.1);
  visibility: ${(props) => (props.showDel === 'show' ? 'visible' : 'hidden')};
`;

const Delete = styled.div`
  .hide {
    display: none;
  }

  .show {
    z-index: 11;
    position: absolute;
    left: 35%;
    top: 35%;
    background: white;
    border: 1px solid gray;
    padding: 3%;
    box-shadow: 2px 3px 5px gray;
    max-width: 500px;
  }
`;

export default connect(getComponentData)(UserAuthorizationStatusTable);
