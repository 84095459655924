//import COA from "./COA/COA";
import COADocuments from './COA/coa-product/COADocuments'
import COAProductList from './COA/coa-products-list/COAProductList'
import EditCOA from './COA/coa-product/add-edit-coa-document/EditCOA'
import CachePage from './enrollment/cache/CachePage'
import Events from './Events/EventList'
import PermissionNotGranted from './GlobalComponents/PermissionNotGranted'
import GCEntryEdit from './GlowChallenge/GCEntryEdit'
import GlowEntry from './GlowChallenge/GlowEntry'
import GlowEntryList from './GlowChallenge/GlowEntryList'
import Incentive from './Incentive/IncentiveList'
import Dashboard from './Pages/Dashboard'
import Permissions from './Permissions/PermissionList'
import ShoppingCategories from './ShoppingConfiguration/Catagories/categories'
import ShoppingCountries from './ShoppingConfiguration/countries/countries'
import ShoppingKits from './ShoppingConfiguration/kits/kits'
import ShoppingProducts from './ShoppingConfiguration/product/products'
import ParentAttribute from './ShoppingConfiguration/shoppping-app/DashBoard/index'
import ParentProductDetails from './ShoppingConfiguration/shoppping-app/ParentProductDetails'
import ParentProductsList from './ShoppingConfiguration/shoppping-app/ParentProductsList'
import ProductDetails from './ShoppingConfiguration/shoppping-app/ProductDetails/ProductDetails'
import EditStarPoint from './ShoppingConfiguration/StarPoint/EditStarPoint'
import StarPointAccountList from './ShoppingConfiguration/StarPoint/StarPointAccountList'
import EntryEdit from './SkincareChallenge/EntryEdit'
import AccountEdit from './SkincareChallenge/SCAccountList/AccountEdit'
import AccountList from './SkincareChallenge/SCAccountList/AccountList'
import EntryList from './SkincareChallenge/SCEntryList/EntryList'
import UserAuthorizationStatusAdd from './User/UserAuthorizationStatusAdd'
import UserAuthorizationStatusAddEdit from './User/UserAuthorizationStatusAddEdit'
import UserAuthorizationStatusTable from './User/UserAuthorizationStatusTable'
import AddCOA from './COA/coa-product/add-edit-coa-document/AddCOA'

const AllRoutes = [
  {
    path: '/',
    exact: true,
    component: Dashboard,
    protected: false,
  },
  {
    path: '/Shopping/ParentProducts',
    component: ParentProductsList,
    protected: true,
  },
  {
    path: '/Shopping/Parent-Product-Detail/:id',
    component: ParentProductDetails,
    protected: true,
  },
  {
    path: '/Shopping/Product-Detail/:id',
    component: ProductDetails,
    protected: true,
  },
  {
    path: '/Shopping/Countries',
    component: ShoppingCountries,
    protected: true,
  },
  {
    path: '/Shopping/Kits',
    component: ShoppingKits,
    protected: true,
  },
  {
    path: '/Shopping/Categories',
    component: ShoppingCategories,
    protected: true,
  },
  {
    path: '/Shopping/Products',
    component: ShoppingProducts,
    protected: true,
  },
  {
    path: '/Shopping/StarPoint',
    component: StarPointAccountList,
    protected: true,
  },
  {
    path: '/StarPoint/Edit/:inventoryId/:country',
    component: EditStarPoint,
    protected: true,
  },
  {
    path: '/Challenge/Accounts',
    component: AccountList,
    protected: true,
  },
  {
    path: '/Challenge/Glow-Submission/:submissionId',
    component: GCEntryEdit,
    protected: true,
  },
  {
    path: '/Challenge/Glow-Entry/:accountid',
    component: GlowEntry,
    protected: true,
  },
  {
    path: '/Challenge/Account/:accountid',
    component: AccountEdit,
    protected: true,
  },
  {
    path: '/Challenge/Entries',
    component: EntryList,
    protected: true,
  },
  {
    path: '/Challenge/Glow-Entries',
    component: GlowEntryList,
    protected: true,
  },
  {
    path: '/Challenge/Entry/:entryId',
    component: EntryEdit,
    protected: true,
  },

  {
    path: '/COA/:coaProductId/Documents',
    component: COADocuments,
    protected: true,
  },
  {
    path: '/COA/:coaProductId/Document/Add',
    component: AddCOA,
    protected: true,
  },
  {
    path: '/COA/:coaProductId/Document/:coaDocumentId/Edit',
    component: EditCOA,
    protected: true,
  },
  // {
  //   path: "/COA/:coaProductId",
  //   component: COA,
  //   protected: true,
  // },
  {
    path: '/COAs',
    component: COAProductList,
    protected: true,
  },

  {
    path: '/Events',
    component: Events,
    protected: true,
  },
  {
    path: '/Incentive',
    component: Incentive,
    protected: true,
  },
  {
    path: '/Permissions',
    component: Permissions,
    protected: true,
  },
  {
    path: '/Settings/users/add',
    component: UserAuthorizationStatusAdd,
    protected: true,
  },
  {
    path: '/Settings/users/edit/:userID',
    component: UserAuthorizationStatusAddEdit,
    protected: true,
  },
  {
    path: '/Settings/users',
    component: UserAuthorizationStatusTable,
    protected: true,
  },
  {
    path: '/Enrollment/Cache',
    component: CachePage,
    protected: false,
  },
  {
    path: '/NoPermission',
    component: PermissionNotGranted,
    protected: false,
  },
  {
    path: '/Shopping/Attributes',
    component: ParentAttribute,
    protected: true,
  },
]

export default AllRoutes
