import React, { useState, useEffect } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADD_DOCUMENT } from '../../../utils/mutations'
import { useToasts } from 'react-toast-notifications'
import { cloudinaryService } from '../../../services'

import { Row, Col } from 'react-bootstrap/'

const AddCOA = (props: any) => {
  const [name, setName] = useState('')
  const [isApproved, setIsApproved] = useState(false)
  const [notes, setNotes] = useState('')
  const [fileInput, setFileInput] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [uploadSuccessful, setUploadSuccessful] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const [hasBlankName, setHasBlankName] = useState(false)
  const { addToast } = useToasts()
  const uploadedOn = new Date().toISOString()
  const sortOrder = 1
  const { coaProductId } = useParams<{ coaProductId: any }>()
  const coaProductIdInt = parseInt(coaProductId)
  const coaProduct = props.location.coaProduct
  const history = useHistory()

  const cloudinaryUpload = async () => {
    const dataCld = new FormData()
    dataCld.append('file', selectedFile)
    dataCld.append('upload_preset', `coa-documents-${coaProduct.countryRegion.name}`)
    dataCld.append('cloud_name', 'zilis')

    try {
      const res = await cloudinaryService.uploadCoa(dataCld)
      setUploadSuccessful(true)
      setFileUrl(res.data)
      addToast('Upload File Successful. ', { appearance: 'success', autoDismiss: true })
    } catch (err: any) {
      addToast('Error occurred while uploading COA or you did not choose a file to upload. ', { appearance: 'error', autoDismiss: true })
    }
  }

  const [addDocument] = useMutation(ADD_DOCUMENT)

  const handleSaveCoa = async (event: any) => {
    event.preventDefault()
    if (!name) {
      handleValidation()
    }
    try {
      if (name) {
        await addDocument({
          variables: {
            input: {
              coaProductId: coaProductIdInt,
              name,
              uploadedOn,
              fileUrl,
              isApproved,
              //sortOrder,
              notes,
            },
          },
        })
        addToast('COA Added Successfully.', { appearance: 'success', autoDismiss: true })
        setName('')

        redirect()
      }
    } catch (e) {
      console.error(e)
      addToast('Error Occurred While Uploading COA.', { appearance: 'error', autoDismiss: true })
    }
  }

  const handleValidation = () => {
    if (!name) {
      setHasBlankName(true)
    }
  }

  const handleName = (event: any) => {
    setName(event.target.value)
  }

  const handleIsApproved = (event: any) => {
    setIsApproved(event.target.checked)
  }

  const redirect = () => {
    const location = {
      pathname: `/COA/${coaProductIdInt}/Documents`,
      state: { from: 'Edit View' },
    }
    history.push(location)
  }

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0]
    setSelectedFile(file)
    setFileInput(e.target.value)
  }

  return (
    <PageWrapper>
      <Row className='text-left'>
        <Col xs={6} md={10}>
          {' '}
          <h1 className='text-secondary'>Add COA Document</h1>
        </Col>
        <Col xl={2} xs={6}>
          <Link to={{ pathname: `/COA/${coaProductIdInt}/Documents`, state: { from: 'Edit View' } }} style={{ display: 'flex', alignSelf: 'center' }}>
            Back to list
          </Link>
        </Col>
      </Row>
      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Product</p>
        </Col>
        <Col xl={2}>
          <p className='text-secondary'>{coaProduct.name}</p>
        </Col>
        <Col xl={1}></Col>
        <Col xl={1}></Col>
        <Col xl={1}>
          <p className='text-secondary'>Region</p>
        </Col>
        <Col xl={1}>
          <p className='text-secondary'>{coaProduct.countryRegion.name}</p>
        </Col>
        <Col xl={2}></Col>
        <Col xl={2}></Col>
      </Row>
      <SolidLine />

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Name</p>
        </Col>
        <Col xl={2}>
          <input value={name} onChange={handleName}></input>
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>
      <Row>{hasBlankName && <small className='form-text text-danger'>Name cannot be blank.</small>}</Row>
      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>File URL</p>
        </Col>
        <Col xl={2}>
          <input type='file' name='document' onChange={handleFileInputChange} value={fileInput} />
        </Col>
        <Col xl={2}></Col>
        <Col xl={3}></Col>
        <Col xl={6}></Col>
      </Row>
      <Row className='text-left mt-3'>
        <Col xl={2}></Col>
        <Col xl={2}>
          <CustomButton onClick={cloudinaryUpload}>Upload</CustomButton>
        </Col>
        <Col xl={2}></Col>
        <Col xl={3}></Col>
        <Col xl={6}></Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={2}>
          <p className='text-secondary'>Is Approved</p>
        </Col>
        <Col xl={2}>
          <CheckBox checked={isApproved} onChange={handleIsApproved} type='checkbox' />
        </Col>
        <Col xl={2}></Col>
        <Col xl={6}></Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={8}>
          <p className='text-secondary'>Notes</p>
          <textarea
            rows={4}
            style={{
              width: '100%',
              border: '1px solid #0F4B8F',
            }}
            value={notes}
            onChange={(e: any) => {
              setNotes(e.target.value)
            }}
          />
        </Col>
      </Row>

      <Row className='text-left mt-3'>
        <Col xl={2}>
          {hasBlankName && <DisabledSaveButton disabled={true}>Save</DisabledSaveButton>}
          {!hasBlankName && <SaveButton onClick={handleSaveCoa}>Save</SaveButton>}
        </Col>
        <Col xl={2}></Col>
      </Row>
    </PageWrapper>
  )
}

export default AddCOA

const PageWrapper = styled.div`
  max-width: 1475px;
  width: 100%;
  font-size: 18px;
`

const SolidLine = styled.div`
  border-top: 10px;
  border: solid;
  color: #c8c8c8;
  border-width: 1px;
`

const CustomButton = styled.button`
  background-color: #09497e;
  color: white;
  font-size: 14px;
  margin: 3px;
  border: none;
  padding: 2px 15px;
  font-weight: 500;
  width: 175px;
  height: 40px;
  text-align: center;

  &:hover {
    background: #345880;
    border: 2px solid #022b53;
    padding: 0px 13px;
  }
`

const SaveButton = styled.button`
  background-color: #09497e;
  color: white;
  font-size: 18px;
  margin: 3px;
  border: none;
  padding: 2px 15px;
  font-weight: 500;
  width: 175px;
  height: 40px;
  text-align: center;

  &:hover {
    background: #345880;
    border: 2px solid #022b53;
    padding: 0px 13px;
  }

  &disabled {
    background: #dddddd;
  }
`

const DisabledSaveButton = styled.button`
  background-color: #d3d3d3;
  color: white;
  font-size: 18px;
  margin: 3px;
  border: none;
  padding: 2px 15px;
  font-weight: 500;
  width: 175px;
  height: 40px;
  text-align: center;
`

const CheckBox = styled.input`
  background-color: #09497e;
  width: 18px;
  height: 18px;

  &:checked {
    background-color: #09497e !important;
  }

  &:hover {
    background: #345880;
    border: 2px solid #022b53;
    padding: 0px 13px;
  }
`
