import { gql, useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'

const useQueryForAttributeType = (refetch: any) => {
  const { addToast } = useToasts()
  const GET_ATTRIBUTE_TYPES = gql`
    query getProductAttibuteTypes {
      productAttributeTypes(order: { sortOrder: ASC }) {
        productAttributeTypeId
        name
        allowedOn
        backgroundColor
        foregroundColor
        sortOrder
        productAttributes {
          productAttributeId
          name
        }
        attributeCount
      }
    }
  `

  const ADD_ATTRIBUTE_TYPE = gql`
    mutation addProductAttributeType($input: AddProductAttributeTypeInput!) {
      addProductAttributeType(input: $input) {
        productAttributeTypeId
        name
        allowedOn
        backgroundColor
        foregroundColor
        sortOrder
        attributes
        notes
      }
    }
  `
  const DELETE_ATTRIBUTE_TYPE = gql`
    mutation removeProductAttributeType($input: RemoveInput!) {
      removeProductAttributeType(input: $input) {
        success
      }
    }
  `

  const CHANGE_ATTRIBUTE_TYPE = gql`
    mutation changeProductAttributeType($input: ChangeProductAttributeTypeInput!) {
      changeProductAttributeType(input: $input) {
        productAttributeTypeId
      }
    }
  `

  const GET_ATTRIBUTE_TYPES_REFRESH = gql`
    query getProductAttibuteTypes {
      productAttributeTypes(order: { sortOrder: ASC }) {
        productAttributeTypeId
        name
        allowedOn
        backgroundColor
        foregroundColor
        sortOrder
        productAttributes {
          productAttributeId
          name
        }
        attributeCount
      }
    }
  `

  const [changeAttributeType] = useMutation(CHANGE_ATTRIBUTE_TYPE, {
    refetchQueries: [{ query: GET_ATTRIBUTE_TYPES }, { query: GET_ATTRIBUTE_TYPES_REFRESH }],
    onCompleted: () => {
      if (refetch) {
        refetch()
      }
    },
  })

  const [addAttributeType, { data: addAttributeTypeData, loading: loadingAttribute, error: errorAttibute }] = useMutation(ADD_ATTRIBUTE_TYPE, {
    refetchQueries: [{ query: GET_ATTRIBUTE_TYPES }],
  })

  const [deleteAttributeType, { data: deleteAttributeTypeData, loading: loadingDeleteAttribute, error: errorDeleteAttibute }] = useMutation(
    DELETE_ATTRIBUTE_TYPE,
    {
      refetchQueries: [{ query: GET_ATTRIBUTE_TYPES }],
    }
  )

  useEffect(() => {
    if (errorDeleteAttibute?.message) {
      addToast(errorDeleteAttibute?.message, { appearance: 'error', autoDismiss: true })
    }
  }, [errorDeleteAttibute])

  const { loading, error, data } = useQuery(GET_ATTRIBUTE_TYPES)
  return { productAttributeTypes: data?.productAttributeTypes, addAttributeType, deleteAttributeType, changeAttributeType }
}

export default useQueryForAttributeType
