
const getPermissions = (state: any) => {
    const { skincarePermissions, permissionFeched} = state.entries;
    const glowPermissions = skincarePermissions?.find((skincarePermission: any) => skincarePermission. permissionAreaId === 4);
    const view =glowPermissions?.permissionLevelId === 2 ||glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;
    const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;
    return {
        view,
        edit,
        permissionFeched, 
    }
}

export default getPermissions
