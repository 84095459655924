import React, { useState, useEffect } from 'react'
import { Row, Col, FormControl, Button } from 'react-bootstrap/'
import styled from 'styled-components'
import { connect } from 'react-redux'
import COAProduct from './COAProduct'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_PRODUCTS } from '../../utils/GQLqueries'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import SpinnerLoader from '../../GlobalComponents/ZilisSpinnerLoader'
import getPermissions from '../common/COASelector'
import { useToasts } from 'react-toast-notifications'

const COAProductList = (props: any) => {
  const { addToast } = useToasts()
  const { error, loading, data, refetch } = useQuery(GET_PRODUCTS)
  const [products, setProducts] = useState<Array<any>>([])
  const [value, setValue] = useState('')
  const [section, setSection] = useState('')
  const { edit, view, permissionFeched, PermissionsError } = props

  const ToastError = (mes: string) => {
    addToast(`${mes}. Please refresh the page. Contact IT if the problem continues.`, {
      appearance: 'error',
    })
  }

  useEffect(() => {
    getProducts()
  }, [data])

  useEffect(() => {
    if (data?.coaProducts) {
      refetch()
    } else if (error) {
      ToastError('The Information Failed To Load')
    }
  }, [data, error])

  useEffect(() => {
    filter()
  }, [value, section])

  const getProducts = () => {
    setProducts(data?.coaProducts || [])
  }

  const handleChange = (e: any, cat: any) => {
    setValue(e.target.value)
    setSection(cat)
    if (value === '' || value === undefined || value === 'All') {
      getProducts()
    }
  }

  const filter = () => {
    let temp: Array<any> = []
    products &&
      products.filter((product: any) => {
        if ((product[section] && product[section].includes(value)) || (product[section] && product[section].toLowerCase().includes(value))) {
          temp.push(product)
        }
      })
    if (temp.length > 0) {
      setProducts(temp)
    } else {
      setValue('')
    }
  }
  const getProductsAfterDelete = () => {
    refetch()
  }
  return (
    <Table>
      <h1>COA Products</h1>
      {!loading && permissionFeched ? (
        !PermissionsError && view ? (
          <>
            {edit && (
              <Row>
                <Col md={12} className='text-right'>
                  <Link to={{ pathname: '/COA/0/Documents' }}>
                    <CustomButton>Add Product</CustomButton>
                  </Link>
                </Col>
              </Row>
            )}
            <Row className='search-box'>
              <Col>
                <input placeholder='ID' onChange={(e) => handleChange(e, 'id')} />
              </Col>
              <Col>
                <input placeholder='Name' onChange={(e) => handleChange(e, 'name')} />
              </Col>
              <Col>
                <select name='Section' defaultValue='Product Section' onChange={(e) => handleChange(e, 'section')}>
                  <option value='Product Section' disabled>
                    Section
                  </option>
                  <option value='UltraCell'>UltraCell</option>
                  <option value='Lishe'>Lishé</option>
                  <option value='All'>Show All</option>
                </select>
              </Col>
              <Col>
                <select name='Region' defaultValue='Region' onChange={(e) => handleChange(e, 'region')}>
                  <option value='Region' disabled>
                    Region
                  </option>
                  <option value='USA'>USA</option>
                  <option value='EU'>Europe</option>
                  <option value='LATAM'>LATAM</option>
                  <option value='All'>Show All</option>
                </select>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row className='name'>
              <Col>ID</Col>
              <Col>Name</Col>
              <Col>Section</Col>
              <Col>Region</Col>
              <Col>Last Updated</Col>
              {edit && <Col>Actions</Col>}
            </Row>
            {!error &&
              (view ? (
                products?.length > 0 &&
                products.map((product) => (
                  <COAProduct
                    getProductsAfterDelete={getProductsAfterDelete}
                    key={product.coaProductId}
                    product={product}
                    fetch={setProducts}
                    edit={edit}
                  />
                ))
              ) : (
                <Redirect to='/NoPermission' />
              ))}
          </>
        ) : (
          <Redirect to='NoPermission' />
        )
      ) : (
        <SpinnerLoader />
      )}
    </Table>
  )
}
export default connect(getPermissions)(COAProductList)

const Table = styled.div`
  max-width: 1475px;
  width: 100%;

  h1 {
    margin: 0 1%;
  }

  .search-box {
    margin: 10px 0px;
  }

  .search-box input {
    width: 100%;
    padding: 3px;
    font: Segoe UI;
    font-size: 15px;
    border: 1px solid #0f4b8f;
    letter-spacing: 0px;
    color: #0f4b8f;
    opacity: 0.4;
  }

  .search-box select {
    padding: 3px;
    width: 100%;
    border: 1px solid #0f4b8f;
    font: Segoe UI;
    font-size: 15px;
    letter-spacing: 0px;
    color: #0f4b8f;
    opacity: 0.5;
  }

  .search-box select option[disabled] {
    font-weight: 600;
  }
  .name {
    margin: 15px 0px;
    text-align: left;
    font: Segoe UI;
    font-size: 20px;
    color: #707070;
    opacity: 1;
  }

  .products {
    text-align: left;
    font: Segoe UI;
    font-size: 20px;
    opacity: 1;
    margin: 15px 0px;
    padding: 0;
  }

  #edit {
    margin: 0px;
    padding: 0;
  }
`

const CustomButton = styled.button`
  background-color: #0f4b8f;
  color: white;
  font-size: 14px;
  margin: 6px 0%;
  border: none;
  padding: 3px 14px;
  font-weight: 500;
  text-align: center;

  &:hover {
    background: #345880;
    border: none;
    padding: 2px 13px;
  }
`
