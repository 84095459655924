/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { ProductStore } from '../component-state/productDetailState'
import { Redirect } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import { CaretUp, CaretDown } from 'react-bootstrap-icons'
import SpinnerLoader from '../../../../GlobalComponents/ZilisSpinnerLoader'
import Chip from '../../../../GlobalComponents/Chip'
import ZilisLoader from '../../../../GlobalComponents/ZilisLoader'

interface IChildProductProps {
  childProducts: Array<any>
}

const ChildProductList = ({ childProducts }: IChildProductProps) => {
  const location = useLocation()
  const [entries, setEntries] = useState<any>(childProducts)
  const [pageNo, setPageNo] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [colSort, setColSort] = useState('entries.id')
  const [sortDirection, setSortDirection] = useState('asc')
  const [nameFilter, setNameFilter] = useState<string>('')

  useEffect(() => {
    childProducts && setEntries(childProducts)
  }, [childProducts])

  const filterRecords = (target: any) => {
    const id = target.id
    const value = target.value
    switch (id) {
      case 'nameFilter':
        setNameFilter(value)
        break
      default:
        null
    }
  }

  const entriesSort = (numPerPage: any, pageNoVal: any, sortInfo: any, sortBy: any) => {
    setColSort(sortInfo)
    setSortDirection(sortBy)
  }

  return (
    <MainListWrapper>
      {true ? (
        <>
          <EntryTable>
            {/*<section className='filter-row'>*/}
            {/*  <input*/}
            {/*    className='filter-input'*/}
            {/*    placeholder='Filter by Name'*/}
            {/*    value={nameFilter}*/}
            {/*    id='nameFilter'*/}
            {/*    onChange={(e: any) => {*/}
            {/*      filterRecords(e.target)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</section>*/}
            <table>
              <thead>
                <tr>
                  <th className='head'>
                    Inventory Id <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.id', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.id', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    Name
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    SKU
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    Attributes
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>
                    Active
                    <br />
                    <CaretUp
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'asc')
                      }}
                    />
                    <CaretDown
                      className='caretIcons'
                      onClick={() => {
                        entriesSort(perPage, pageNo, 'entries.createdDate', 'desc')
                      }}
                    />
                  </th>
                  <th className='head'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {entries &&
                  entries.length > 0 &&
                  entries.map((entry: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{entry.productId}</td>
                        <td>{entry.inventory.name}</td>
                        <td className='name-td' title={entry.inventory.name}>
                          {entry.inventory.productSku}
                        </td>
                        <td className='attr-td'>
                          {entry &&
                            entry.productAttributes &&
                            entry.productAttributes.length > 0 &&
                            entry.productAttributes.map((attr: any, index: number) => {
                              return (
                                <div key={index} className='attr-chip-wrapper'>
                                  <Chip label={attr.name.en} color={attr.productAttributeType.backgroundColor} />
                                </div>
                              )
                            })}
                        </td>

                        <td>{entry.inventory.active === 1 ? 'Active' : 'Not Active'}</td>
                        <td>
                          <Link to={{ pathname: `/Shopping/Product-Detail/${entry.productId}`, state: { previousLocation: location.pathname } }}>
                            Edit
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </EntryTable>
        </>
      ) : (
        <Redirect to='/NoPermission' />
      )}
    </MainListWrapper>
  )
}

export default ChildProductList

const MainListWrapper = styled.div`
  width: 100%;
  max-width: 1475px;
  margin: 0 auto;
`

const EntryTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  margin: 0;

  table {
    width: 100%;
    th {
      text-align: left;
    }

    tr {
      &:nth-child(even) {
        background: #d0d1d1;
      }

      td {
        padding: 5px 0;
        text-align: left;
      }
    }

    .attr-td {
      display: flex;
      flex-flow: row wrap;
      // max-width: 400px;
    }
    .name-td {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }
  }

  .head {
    font-size: 18px;
    font-weight: 400;
    color: rgb(94, 93, 93);
    margin: 1px 1vw;
    border-bottom: 1px solid #094a8a;
  }

  .button-row {
    display: flex;
    align-items: flex-end;
    width: 100%;
    .add-entry-btn {
      width: 200px;
      height: 40px;
    }
  }

  .filter-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2%;

    .filter-input {
      border: 1px solid #0f4b8f;
      width: 275px;
      height: 40px;
      padding-left: 8px;
    }
  }

  .table-action-link {
    color: dodgerblue;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .attr-chip-wrapper {
    margin: 1px 2px;
  }
`
