import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import getComponentData from './UserPermissionSelector';
import { ADD_NEW_USER, UPDATE_USER } from '../utils/mutations';
import { GET_PERMISSIONS } from '../utils/GQLqueries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { Redirect } from 'react-router-dom';
import ZilisLoader from '../GlobalComponents/ZilisLoader';
import { useHistory } from 'react-router';

const UserAuthorizationStatusAddEdit = ({ view, edit, permissionFeched, ...props }) => {
  const options = [
    { id: 1, value: 'Not Allowed' },
    { id: 2, value: 'View' },
    { id: 3, value: 'View and Edit' },
    { id: 4, value: 'View, Edit and Approve' },
  ];
  const permissionResponse = useQuery(GET_PERMISSIONS, {
    variables: { id: props.location.state.id },
  });
  const [userPermission, setUserPermission] = useState([]);
  const [shopConfigCountryPermssion, setShopConfigCountryPermission] = useState(null);
  const [shopConfigKitsPermssion, setShopConfigKitsPermission] = useState(null);
  const [shopConfigCategoriesPermssion, setShopConfigCategoriesPermission] = useState(null);
  const [shopConfigProductsPermssion, setShopConfigProductsPermission] = useState(null);
  const [skinAccountsPermission, setSkinAccountPermission] = useState(null);
  const [skinEntriesPermission, setSkinEntriesPermission] = useState(null);
  const [eventCalendarCorpPermsission, setEventCalendarCorpPermission] = useState(null);
  const [eventCalendarAMBPermsission, setEventCalendarAMBPermission] = useState(null);
  const [incentiveTripPermission, setIncentiveTripPermission] = useState(null);
  const [coaProductsPermission, setProductsPermission] = useState(null);
  const [coaDocumentsPermission, setDocumentsPermission] = useState(null);
  const [RolesPermission, setRolePermission] = useState(null);
  const [adminSettingsUserAccountsPermission, setAdminSettingsUserAccountsPermission] = useState(null);
  const [adminSettingsPermissionsPermission, setAdminSettingsPermissionsPermission] = useState(null);
  const [updatedUserPermission, setUpdatedUserPermission] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [saveDisable, setSaveDisable] = useState(false);
  const history = useHistory()

  const { addToast } = useToasts();

  const getPermissions = async () => {
    props.location.state.id && setUserPermission(permissionResponse?.data?.userPermissions);
  };

  useEffect(() => {
    if (props.location.state?.email) {
      getPermissions(props.location.state.email);
      setEmail(props.location.state.email);
      setName(props.location.state.name);
    }
  }, [permissionResponse?.data, permissionResponse?.error]);

  const generateDefaultValue = (area) => {
    const level =
      userPermission &&
      permissionResponse?.data?.userPermissions.length > 0 &&
      permissionResponse?.data?.userPermissions.find((item) => {
        return item.permissionAreaName === area;
      }) &&
      userPermission.find((item) => {
        return item.permissionAreaName === area;
      })?.permissionLevelId;
    if (level) {
      return level;
    } else return options[0].id;
  };

  const formattedDate = props.location.state?.createdDate?.substr(0, props.location.state.createdDate.indexOf('Z'));

  const createdDate = formattedDate ? moment(formattedDate).format('MM/DD/YYYY h:mm:ss a').toUpperCase() : '';

  const lastModified = props.location.state?.createdDate?.substr(0, props.location.state.createdDate.indexOf('Z'));
  const dateLastModified = lastModified ? moment(lastModified).format('MM/DD/YYYY h:mm:ss a').toUpperCase() : '';

  const [changeUser] = useMutation(UPDATE_USER);
  const [addUser] = useMutation(ADD_NEW_USER);

  const onSaveUerPermissions = async () => {
    if (updatedUserPermission?.length) {
      setSaveDisable(true)
      const res = await changeUser({
        variables: {
          userId: props.location.state.id,
          email,
          name,
          userPermissions: updatedUserPermission,
        },
      });
    
    if (res.data?.changeUser.user) {
      addToast(`Permissions are updated`, {
        appearance: 'success',
      });
      history.push('/Settings/users')
    } else {
      addToast(`Permissions are not updated. Please refresh the page. Contact IT if the problem continues.`, {
        appearance: 'error',
      });
    }
  }
  else{
    addToast(`No permission changed`, {
      appearance: 'error',
    });
  }
  }
  return (
    <Container>
      {permissionFeched?(edit?<>
      <UserTitleContainer>
        <Title>Account Details</Title>
        <Link to='/Settings/users' style={{ display: 'flex', alignSelf: 'center' }}>
          Back to List
        </Link>
      </UserTitleContainer>
      <AccountDetailsContainer>
        <div>
          <label>Email</label>
          <StyledInput readOnly={!edit} defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
          <br />
          <label>Name: </label>
          <StyledInput readOnly={!edit} defaultValue={name} onChange={(e) => setName(e.target.value)} />
          <br />
          <span
            style={{
              display: 'flex',
              whiteSpace: 'nowrap',
            }}
          >
            <label> Date Created:</label>
            <DateCreatedContainer>{createdDate}</DateCreatedContainer>
          </span>
        </div>
        <DateModifiedContainer>
          <label
            style={{
              display: 'flex',
              whiteSpace: 'nowrap',
            }}
          >
            Date Last Modified:
            {dateLastModified}
          </label>
          <label>
            <div />
            Created by:
            {props.location.state && props.location.state.createdBy ? ' ' + props.location.state.createdBy : ''}
          </label>
        </DateModifiedContainer>

        <div />
      </AccountDetailsContainer>
      <hr />
      <AccountConfigContainer>
        <div>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#707070',
              textAlign: 'left',
            }}
          >
            Shopping Configuration
          </p>
          <div>
            <label name='countries'>Countries</label>
            <StyledSelect
              name={6}
              value={shopConfigCountryPermssion || generateDefaultValue('Countries')}
              onChange={(e) => {
                // const checkupdate = updatedUserPermission.find( (updated) => e.target.name == updated.permissionAreaId)
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                setShopConfigCountryPermission(e.target?.value);
              }}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='kits'>Kits</label>
            <StyledSelect
              name={7}
              value={shopConfigKitsPermssion || generateDefaultValue('Kits')}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setShopConfigKitsPermission(e.target?.value);
              }}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='categories'>Categories</label>
            <StyledSelect
              name={8}
              value={shopConfigCategoriesPermssion || generateDefaultValue('Categories')}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setShopConfigCategoriesPermission(e.target.value);
              }}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='products'>Products</label>
            <StyledSelect
              name={4}
              value={shopConfigProductsPermssion || generateDefaultValue('Products')}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setShopConfigProductsPermission(e.target.value);
              }}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
        </div>
        <div style={{ marginTop: '48px' }}>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#707070',
              textAlign: 'left',
            }}
          >
            COAs
          </p>
          <div>
            <label name='countries'>Products</label>
            <StyledSelect
              name={4}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setProductsPermission(e.target.value);
              }}
              value={coaProductsPermission || generateDefaultValue('Products')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='countries'>Documents</label>
            <StyledSelect
              name={5}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setDocumentsPermission(e.target.value);
              }}
              value={coaDocumentsPermission || generateDefaultValue('Products')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#707070',
              textAlign: 'left',
            }}
          >
            Admin Settings
          </p>
          <div>
            <label name='categories'>User Accounts</label>
            <StyledSelect
              name={13}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setAdminSettingsUserAccountsPermission(e.target.value);
              }}
              value={adminSettingsUserAccountsPermission || generateDefaultValue('Categories')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='products'>Permissions</label>
            <StyledSelect
              name={14}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setAdminSettingsPermissionsPermission(e.target.value);
              }}
              value={adminSettingsPermissionsPermission || generateDefaultValue('Products')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='kits'>Roles</label>
            <StyledSelect
              name={15}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setRolePermission(e.target.value);
              }}
              value={RolesPermission || generateDefaultValue('Kits')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
        </div>
      </AccountConfigContainer>
      <BottomAccountConfig>
        <div>
           
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#707070',
              textAlign: 'left',
              marginTop: '-30px',
            }}
          >
            Skincare Challenge
          </p>
          <div>
            <label name='countries'>Accounts</label>
            <StyledSelect
              name={1}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setSkinAccountPermission(e.target.value);
              }}
              value={skinAccountsPermission || generateDefaultValue('Accounts')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <label name='kits'>Entries</label>
            <StyledSelect
              name={3}
              onChange={(e) => {
                setUpdatedUserPermission([
                  ...updatedUserPermission,
                  { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                ]);
                edit && setSkinEntriesPermission(e.target.value);
              }}
              value={skinEntriesPermission || generateDefaultValue('Entries')}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                color: '#707070',
                textAlign: 'left',
              }}
            >
              Event Calendar
            </p>
            <div>
              <label name='countries'>Corp Events</label>
              <StyledSelect
                name={10}
                onChange={(e) => {
                  setUpdatedUserPermission([
                    ...updatedUserPermission,
                    { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                  ]);
                  edit && setEventCalendarCorpPermission(e.target.value);
                }}
                value={eventCalendarCorpPermsission || generateDefaultValue('Corp Events')}
              >
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.value}
                  </option>
                ))}
              </StyledSelect>
            </div>
            <div>
              <label name='kits'>AMB Events</label>
              <StyledSelect
                name={11}
                onChange={(e) => {
                  setUpdatedUserPermission([
                    ...updatedUserPermission,
                    { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                  ]);
                  edit && setEventCalendarAMBPermission(e.target.value);
                }}
                value={eventCalendarAMBPermsission || generateDefaultValue('AMB Events')}
              >
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.value}
                  </option>
                ))}
              </StyledSelect>
            </div>
          </div>
          <div>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                color: '#707070',
                textAlign: 'left',
              }}
            >
              Incentive Trip
            </p>
            <div>
              <label name='countries'>Incentive Trip</label>
              <StyledSelect
                name={12}
                onChange={(e) => {
                  setUpdatedUserPermission([
                    ...updatedUserPermission,
                    { permissionAreaId: parseInt(e.target.name), permissionLevelId: parseInt(e.target.value) },
                  ]);
                  edit && setIncentiveTripPermission(e.target.value);
                }}
                value={incentiveTripPermission || generateDefaultValue('Incentive Trip')}
              >
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.value}
                  </option>
                ))}
              </StyledSelect>
            </div>
          </div>
        </div>
      </BottomAccountConfig>
      <div style={{ display: 'flex' }}>
        <StyledButton  disabled={saveDisable} onClick={() => onSaveUerPermissions()}>Save</StyledButton>
      </div>
      </>:<Redirect to='/NoPermission'/>):<ZilisLoader/>}
    </Container>
  );
};

const StyledButton = styled(Button)`
  background-color: #0f4b8f;

  color: white;

  height: 40px;

  font-weight: bold;

  width: 130px;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 45px;
`;

const BottomAccountConfig = styled.div`
  display: flex;
`;
const Container = styled.div`
  width: 100%;
  label {
    width: 100px;
    text-align: left;
  }
  max-width: 1600px;
  @media screen and (max-width: 1200px) {
  }
`;

const AccountConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -40px;
  max-width: 1225px;
`;

const UserTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.p`
  color: #707070;
  font-size: 36px;
  text-align: left;
`;

const AccountDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  label {
    color: #707070;
  }
  p {
    color: #707070;
  }
`;
const StyledInput = styled.input`
  border: 2px solid #0f4b8f;
  width: 280px;
  @media screen and (min-width: 1400px) {
    margin-left: 65px;
  }
`;

const StyledSelect = styled.select`
  border: 2px solid #0f4b8f;
  width: 270px;
  @media screen and (min-width: 1400px) {
    margin-left: 65px;
  }
  @media screen and (max-width: 700px) {
    margin-left: 45px;
    width: 180px;
  }
`;

const DateCreatedContainer = styled.div`
  @media screen and (min-width: 1400px) {
    margin-left: 65px;
  }
`;
const DateModifiedContainer = styled.div`
  padding-right: min(150px, 70%);
`;
export default connect(getComponentData)(UserAuthorizationStatusAddEdit);
