import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { REMOVE_COA_DOCUMENT, UPDATE_DOCUMENT_SORT_ORDER } from '../../utils/mutations'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatDateOnly } from '../../utils/globalHelper'
import { useMutation } from '@apollo/react-hooks'
import ConfirmDel from '../common/ConfirmDel'
import ICoaDocument from '../common/models/interfaces/ICoaDocument'
import ICoaProduct from '../common/models/interfaces/ICoaProduct'

interface COADocumentsTableProps {
  tableData: Array<ICoaDocument>
  coaProduct: ICoaProduct
  refetch: any
}

const COADocumentsTable = ({ tableData, coaProduct, refetch }: COADocumentsTableProps) => {
  const productId = coaProduct && coaProduct.coaProductId

  const [data, setData] = useState<Array<ICoaDocument>>([])

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  const [showDel, setShowDel] = useState(false)
  const [itemToDel, setItemToDel] = useState<any>({})
  const [updateSortOrder] = useMutation(UPDATE_DOCUMENT_SORT_ORDER)
  //const [removeDocument] = useMutation(REMOVE_COA_DOCUMENT);

  const handleUpdateAfterDelete = () => {
    refetch()
  }

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const showDelete = () => {
    setShowDel(!showDel)
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    opacity: isDragging ? 0.5 : 1,

    ...draggableStyle,
  })

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(data, result.source.index, result.destination.index)
    const updatedItems: any = Array.from(items).map((document: any, i) => {
      let { __typename, ...documentItems } = document
      return {
        ...documentItems,
        sortOrder: (i + 1) * 100,
      }
    })

    setData(updatedItems)
    updateSortOrder({
      variables: { coaProductId: productId, coaDocuments: updatedItems },
    })
  }

  const handleDeleteDocument = (item: any) => {
    setItemToDel(item)
    showDelete()
    refetch()
  }

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <table
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                width: '100%',
                fontSize: '20px',
                color: '#707070',
                borderCollapse: 'separate',
                borderSpacing: '20px',
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Approved</th>
                  <th>Uploaded On</th>
                  <th>Archived</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item: any, index) => {
                    const uploadedOnDate = formatDateOnly(item.uploadedOn)

                    return (
                      <Draggable key={item.coaDocumentId} draggableId={item.name} index={index}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <td>
                              <span>
                                <i role='Handle' className='fas fa-grip-lines'></i>
                              </span>
                            </td>
                            <td>
                              {item.fileUrl ? (
                                <a
                                  // href={`https://res.cloudinary.com/zilis/image/upload/${item.fileUrl}`}
                                  href={`${item.fileUrl}`}
                                  target='_blank'
                                >
                                  {item.name}
                                </a>
                              ) : (
                                item.name
                              )}
                            </td>
                            <td>{item.isApproved ? 'Yes' : 'No'}</td>
                            <td>{uploadedOnDate}</td>
                            <td>{item.isArchived ? 'Yes' : 'No'}</td>
                            <td>
                              <Link
                                to={
                                  {
                                    pathname: `/COA/${productId}/Document/${item.coaDocumentId}/Edit`,
                                    coaProduct: coaProduct,
                                    coaDocument: item,
                                  } as any
                                }
                              >
                                Edit
                              </Link>{' '}
                              |{' '}
                              <span style={{ color: '#007BFF', cursor: 'pointer' }} onClick={() => handleDeleteDocument(item)}>
                                Delete
                              </span>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
      <Overlay showDel={showDel} />
      <Delete>
        <div className={showDel ? 'show' : 'hide'}>
          <ConfirmDel document={itemToDel} type={'COA'} show={showDelete} handleUpdateAfterDelete={handleUpdateAfterDelete} name={itemToDel.name} />
        </div>
      </Delete>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  th {
    text-align: left;
  }
  tr {
    text-align: left;
  }
`

const Overlay = styled.div<{ showDel: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => (props.showDel ? 'visible' : 'hidden')};
`

const Delete = styled.div`
  .hide {
    display: none;
  }

  .show {
    z-index: 12;
    position: absolute;
    left: 35%;
    top: 35%;
    background: white;
    border: 1px solid gray;
    padding: 3%;
    box-shadow: 2px 3px 5px gray;
    max-width: 500px;
  }
`

export default COADocumentsTable
