const getComponentData = (state) => {
  const { skincarePermissions, permissionFeched, PermissionsError} = state.entries;
  const { products } = state.products;
  const glowPermissions = skincarePermissions?.find(
    (skincarePermission) => skincarePermission.permissionAreaId === 9
  );
  const view =
    glowPermissions?.permissionLevelId === 2 ||
    glowPermissions?.permissionLevelId === 3 ||
    glowPermissions?.permissionLevelId === 4;
  const edit = glowPermissions?.permissionLevelId === 3 || glowPermissions?.permissionLevelId === 4;

  return {
    view,
    edit,
    permissionFeched,
    PermissionsError,
    products
  };
};

export default getComponentData;
