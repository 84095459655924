import gql from 'graphql-tag'

export const GET_ATTRIBUTE_TYPES = gql`
  query getProductAttibuteTypes($allowedOn: [Int!]!) {
    productAttributeTypes(
      where: { allowedOn: { in: $allowedOn } }
      order: { sortOrder: ASC }
    ) {
      productAttributeTypeId
      name
      allowedOn
      backgroundColor
      foregroundColor
      sortOrder
      productAttributes {
        productAttributeId
        name
      }
      attributeCount
    }
  }
`
