import axios from 'axios'
import { IApiConfiguration } from '../config/IAPIConfiguration'
import { ServiceResponse } from '../ServiceResponse'

const serviceRoutes = {
  upload: 'v1_1/zilis/upload',
}

interface ICloudinaryService {
  uploadCoa(payload: any): Promise<any>
}

export class CloudinaryService implements ICloudinaryService {
  private _cloudinaryClient

  constructor(apiConfiguration: IApiConfiguration) {
    this._cloudinaryClient = axios.create(apiConfiguration)
  }

  async uploadCoa(payload: any): Promise<ServiceResponse> {
    const serviceResponse = new ServiceResponse()
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    try {
      const res = await this._cloudinaryClient.post(serviceRoutes.upload, payload, headers)
      serviceResponse.data =  res.data.secure_url
      return serviceResponse 
    } catch (err: any) {
        serviceResponse.success = false
        serviceResponse.messages = err.message
      return serviceResponse 
    }
  }

  private splitCloudinaryUrl(url: any) {
    const filePath = url.split('/')
    const newFilePath = `${filePath[6]}/admin.coa/coa/${filePath[9]}`
    return newFilePath
  }
}
