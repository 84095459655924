import gql from 'graphql-tag'

// Queries

export const GET_PARENT_PRODUCTS = gql`
  query getProductParents($skip: Int!, $take: Int!) {
    productParents(skip: $skip, take: $take, order: { inventory: { productSku: ASC } }) {
      items {
        productParentId
        inventoryId
        inventory {
          inventoryId
          productSku
          name
          active
        }
        productAttributes {
          productAttributeId
          name
          sortOrder
          productAttributeType {
            productAttributeTypeId
            name
            backgroundColor
            foregroundColor
          }
        }
        productCount
      }
      # pageInfo is optional
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_PRODUCT_PARENT = gql`
  query getProductParent($productParentId: Int!) {
    productParents(
      where: { productParentId: { eq: $productParentId } }
      order: [{ productParentId: ASC }]
    ) {
      items {
        productParentId
        inventory {
          inventoryId
          productSku
          name
          active
          sortOrder
        }
        productAttributes {
          productAttributeId
          name
          sortOrder
          productAttributeType {
            productAttributeTypeId
            backgroundColor
            foregroundColor
          }
        }
        products {
          productId
          inventory {
            inventoryId
            productSku
            name
            active
          }
          productAttributes {
            productAttributeId
            name
            sortOrder
            productAttributeType {
              productAttributeTypeId
              backgroundColor
              foregroundColor
            }
          }
        }
        productCount
      }
    }
  }
`

export const GET_PRODUCT_PARENT_BASIC = gql`
  query getProductParentBasic($productParentId: Int!) {
    productParents(
			where: { productParentId: { eq: $productParentId } }
      order: [{ productParentId: ASC }]
		) {
      items {
        productParentId
        inventory {
          inventoryId
          productSku
          name
        }
      }
    }
  }
`

export const GET_PRODUCT = gql`
  query getProducts($productId: Int!) {
    products(where: { productId: { eq: $productId } }) {
      productId
      productParentId
      inventory {
        inventoryId
        productSku
        name
        active
      }
      productAttributes {
        productAttributeId
        name
        sortOrder
        productAttributeType {
          productAttributeTypeId
          name
          backgroundColor
          foregroundColor
          sortOrder
        }
      }
      attributeCount
    }
  }
`

// Mutations

export const ADD_PRODUCT_PARENT_ATTRIBUTE = gql`
  mutation addProductAttributeProductParent($input: ProductAttributeProductParentInput!) {
    addProductAttributeProductParent(input: $input) {
      productParentId
      productAttributeId
    }
  }
`

export const REMOVE_PRODUCT_PARENT_ATTRIBUTE = gql`
  mutation removeProductAttributeProductParent($input: ProductAttributeProductParentInput!) {
    removeProductAttributeProductParent(input: $input) {
      success
    }
  }
`

export const ADD_PRODUCT_ATTRIBUTE = gql`
  mutation addProductAttributeProduct($input: ProductAttributeProductInput!) {
    addProductAttributeProduct(input: $input) {
      productId
      productAttributeId
    }
  }
`

export const REMOVE_PRODUCT_ATTRIBUTE = gql`
  mutation removeProductAttributeProduct($input: ProductAttributeProductInput!) {
    removeProductAttributeProduct(input: $input) {
      success
    }
  }
`
