import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import styled from 'styled-components'
// jpuri.github.io/react-draft-wysiwyg/#/docs
interface IWysiwygProps {
  initalValue: any
  updateValue: React.Dispatch<React.SetStateAction<string>>
}

const WysiwygEditor = ({ initalValue, updateValue }: IWysiwygProps) => {
  const [editorState, setEditorState] = useState<any>('')

  useEffect(() => {
    if (initalValue) {
      const blocksFromHtml = htmlToDraft(initalValue)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }, [])

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState)
    editorState && updateAndConvert()
  }

  const updateAndConvert = () => {
    const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    updateValue(newValue)
  }

  return (
    <WysiwygContainer>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'remove', 'history'],
          inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
          link: { inDropdown: true },
          history: { inDropdown: true },
          fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30] },
        }}
      />
    </WysiwygContainer>
  )
}

export default WysiwygEditor
const WysiwygContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #000;
  min-height: 180px;

  .update-btn {
    border: none;
    width: 100px;
    height: 40px;
    background-color: #0f4b8f;
    color: #fff;
    font-size: 18px;
    margin-top: 55px;
  }
`
