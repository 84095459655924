export const GET_AZPROFILE_SUCCESS = '[AZURE LOGIN] GET AZURE AD PROFILE SUCCESS';
export const GET_AZPROFILE_FAILURE = '[AZURE LOGIN] GET AZURE AD PROFILE FAILURE';
export const GET_AZPROFILE_IMAGE_SUCCESS = '[AZURE LOGIN] GET AZURE AD PROFILE IMAGE SUCCESS';
export const GET_AZPROFILE_IMAGE_FAILURE = '[AZURE LOGIN] GET AZURE AD PROFILE IMAGE FAILURE';
export const SET_AZAD_ACCESS_TOKEN = '[AZURE LOGIN] SET AZURE AD ACCESSS TOKEN SUCCESS';
export const SET_GRAPH_TOKEN = '[AZURE LOGIN] SET AZURE GRAPH ACCESSS TOKEN SUCCESS';
export const RESET_STATE = '[AZURE LOGIN] RESET STATE';

export const GET_ZILIS_SERVICE_TOKEN_SUCCESS = '[AZURE LOGIN] GET ZILIS SERVICE TOKEN FROM AZURE SUCCESS';
export const GET_ZILIS_SERVICE_TOKEN_FAILURE = '[AZURE LOGIN] GET ZILIS SERVICE TOKEN FROM AZURE FAILURE';
export const GET_ZILIS_SERVICE_EMAIL_SUCCESS = '[AZURE LOGIN] GET ZILIS SERVICE EMAIL FROM AZURE SUCCESS';