import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { regionData } from '../common/data/RegionData'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_DOCUMENTS_BY_PRODUCT_ID, GET_SECTIONS, GET_PRODUCT_BY_ID } from '../../utils/GQLqueries'
import { ADD_PRODUCT, EDIT_PRODUCT } from '../../utils/mutations'
import IAddEditSectionReq from '../common/models/interfaces/IAddEditSectionReq'
import ICoaProduct from '../common/models/interfaces/ICoaProduct'

import COADocumentsTable from './COADocumentsTable'
import WysiwygEditor from '../common/WysiwygEditor'
import { Button } from 'react-bootstrap'
import { formatDateToServiceReq, formatSystemDateToViewDate } from '../../utils/globalHelper'
import ICoaSection from '../common/models/interfaces/ICoaSection'
import ICoaDocument from '../common/models/interfaces/ICoaDocument'

const COADocuments = (props: any) => {
  const { coaProductId } = useParams<{ coaProductId: string }>()
  const productIdInt = parseInt(coaProductId)
  const [currentProductId, setCurrentProductId] = useState<number>(productIdInt || 0)
  const { error, data, refetch } = useQuery(GET_DOCUMENTS_BY_PRODUCT_ID, {
    fetchPolicy: 'no-cache',
    variables: { coaProductId: currentProductId },
  })
  const { data: product, refetch: refetchProductInfo } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { coaProductId: currentProductId },
  })
  const { data: sections } = useQuery(GET_SECTIONS)

  const { addToast } = useToasts()
  const [documents, setDocuments] = useState<Array<ICoaDocument>>([])
  const [sectionsData, setSectionsData] = useState([])
  const [section, setSection] = useState<any>('')
  const [region, setRegion] = useState<any>('')
  const [productExists, setProductExists] = useState(false)
  const [editCOA, setEditCoa] = useState<boolean>(false)
  const [addCoa, setAddCoa] = useState<boolean>(false)
  const [currentProductData, setCurrentProductData] = useState<ICoaProduct | null>(null)

  const ToastError = (mes: string) => {
    addToast(`${mes}. Please refresh the page. Contact IT if the problem continues.`, {
      appearance: 'error',
    })
  }
  useEffect(() => {
    productIdInt && setCurrentProductId(productIdInt)
  }, [productIdInt])

  useEffect(() => {
    product && setCurrentProductData(product.coaProducts[0])
  }, [product])

  useEffect(() => {
    props && setAddCoa(props.location.state === undefined)
  }, [props])

  useEffect(() => {
    if (!error && props.history.action === 'PUSH') {
      refetch()
      refetchProductInfo()
    } else if (error && props.history.action !== 'PUSH') {
      ToastError('The Information Failed To Load')
    }
  }, [error])

  useEffect(() => {
    getSections()
    refetch()
  }, [])

  useEffect(() => {
    data && setDocuments(data?.coaDocuments)
  }, [data])

  useEffect(() => {
    currentProductData && sectionsData && setSection(determieSection(currentProductData?.coaSection?.coaSectionId))
  }, [sectionsData])

  useEffect(() => {
    currentProductData && setRegion(determieRegion(currentProductData?.countryRegion?.countryRegionId))
    currentProductData && sectionsData && setSection(determieSection(currentProductData?.coaSection?.coaSectionId))
  }, [currentProductData])

  const getSections = () => {
    setSectionsData(sections?.coaSections)
  }

  const determieSection = (_sectionId: number) => {
    return sectionsData.find((section: any) => {
      return section.coaSectionId === _sectionId
    })
  }

  const determieRegion = (_regionId: number) => {
    return (
      regionData.find((region) => {
        return region.value === _regionId
      })?.title || ''
    )
  }

  const refetchCoaOnUpdate = async () => {
    await refetchProductInfo()
  }

  return (
    <COADocumentsContainer>
      <Container>
        <p className='page-title'>COA Product</p>
        <Link to={'/COAs'} className='coa-back-link'>
          Back to list
        </Link>
      </Container>
      {editCOA || addCoa ? (
        <AddEditCoaView
          currentProductData={currentProductData}
          sectionsData={sectionsData}
          addCoa={addCoa}
          editCOA={editCOA}
          setEditCoa={setEditCoa}
          productIdInt={currentProductId}
          setCurrentProductId={setCurrentProductId}
          refetchCoaOnUpdate={refetchCoaOnUpdate}
          setAddCoa={setAddCoa}
        />
      ) : (
        <div className='readonly-content'>
          <div className='content-wrapper'>
            <div className='commom-content-container'>
              <label className='lbl-text'>Name:</label>&nbsp;
              <p>{currentProductData?.name}</p>
            </div>
            <div className='commom-content-container'>
              <label className='lbl-text'>Display Name:</label>
              <div dangerouslySetInnerHTML={{ __html: currentProductData?.displayName ?? '' }}></div>
            </div>
          </div>

          <div className='content-wrapper'>
            <div className='commom-content-container'>
              <label className='lbl-text'>Region:</label>
              <p>{region}</p>
            </div>
            <div className='commom-content-container'>
              <label className='lbl-text'>Section:</label>
              <p>{section?.name}</p>
            </div>
          </div>

          <div className='content-wrapper'>
            <div className='commom-content-container'>
              <label className='lbl-text'>Last Updated:</label>
              <p>{formatSystemDateToViewDate(currentProductData?.lastUpdatedOn ?? '')}</p>
            </div>
            <div className='commom-content-container'>
              <label className='lbl-text'>Sort Order:</label>
              <p>{currentProductData?.sortOrder}</p>
            </div>
          </div>
        </div>
      )}
      <div
        className=''
        style={{
          width: '100%',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!editCOA && !addCoa && (
          <div>
            <StyledButton onClick={() => setEditCoa(!editCOA)}>Edit&nbsp;{currentProductData?.name}</StyledButton>
          </div>
        )}
      </div>
      <hr style={{ backgroundColor: '#d0d0d0', height: '1px' }} />
      <Container>
        <p style={{ fontSize: '32px' }}>Documents</p>
        {!addCoa && (
          <Link
            to={
              {
                pathname: `/COA/${currentProductId}/Document/Add`,
                coaProduct: currentProductData,
              } as any
            }
          >
            <StyledButton disabled={!productExists && props.match.params.productId}>Add Document</StyledButton>
          </Link>
        )}
      </Container>
      <COADocumentsTable tableData={documents} coaProduct={product && product.coaProducts[0]} refetch={refetch} />
    </COADocumentsContainer>
  )
}

const COADocumentsContainer = styled.div`
  width: 100%;

  .page-title {
    font-size: 32px;
  }
  .coa-back-link {
    display: flex;
    align-self: center;
  }

  .commom-content-container {
    width: 100%;
    display: flex;
    align-items: baseline;
    margin: 1% 0;

    .lbl-text {
      font-size: 20px;
      margin-right: 20px;
      font-weight: 600;
    }
    p {
      margin: 0;
      font-size: 18px;
    }
  }

  .readonly-content {
    width: 100%;
    display: flex;
    margin-bottom: 2%;
    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .content-wrapper {
      width: 50%;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  color: #707070;
`

const StyledButton = styled(Button)`
  background-color: #0f4b8f;
  color: white;
  height: 40px;
  font-weight: bold;
  margin-right: 74px;

  &.cancel-btn {
    background-color: red;
    border: none;
    width: 175px;
  }

  &.save-btn {
    width: 175px;
  }
`

export default COADocuments
interface IAddEditCoaViewProps {
  currentProductData: ICoaProduct | null
  sectionsData: any
  addCoa: any
  editCOA: any
  setEditCoa: any
  productIdInt: number
  setCurrentProductId: (productId: number) => void
  refetchCoaOnUpdate: any
  setAddCoa: any
}

const AddEditCoaView = ({
  currentProductData,
  sectionsData,
  addCoa,
  editCOA,
  setEditCoa,
  productIdInt,
  setCurrentProductId,
  refetchCoaOnUpdate,
  setAddCoa,
}: IAddEditCoaViewProps) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const [addProduct] = useMutation(ADD_PRODUCT)
  const [updateProduct] = useMutation(EDIT_PRODUCT)
  const [regionId, setRegionId] = useState<number>(currentProductData?.countryRegion?.countryRegionId ?? 1)
  const [section, setSection] = useState<ICoaSection | any>(currentProductData?.coaSection ?? {})
  const [productName, setProductName] = useState<string>(currentProductData?.name ?? '')
  const [displayName, setDisplayName] = useState<string>(currentProductData?.displayName ?? '')
  const [sortOrder, setSortOrder] = useState<number>(currentProductData?.sortOrder ?? 0)
  const [lastUpdated, setLastUpdated] = useState<any>()
  const [productExists, setProductExists] = useState<boolean>(false)
  const [validation, setValidation] = useState<boolean>(false)

  useEffect(() => {
    !currentProductData?.coaSection && setSection(sectionsData[0])
  }, [])

  useEffect(() => {
    if (currentProductData?.lastUpdatedOn) {
      const parsedDate = currentProductData?.lastUpdatedOn.split('T')[0]
      setLastUpdated(parsedDate)
    } else {
      const date = new Date().toISOString().split('T')[0]
      setLastUpdated(date)
    }
  }, [])

  const handleSaveProduct = async (event: any) => {
    event.preventDefault()
    if (productName === '' || displayName === '' || displayName.trim() === '<p></p>') {
      addToast('Missing Fields Could Not Save.', {
        appearance: 'error',
      })
      return
    }

    const dataToSubmit: IAddEditSectionReq = {
      name: productName,
      displayName: displayName,
      countryRegionId: regionId,
      coaSectionId: section.coaSectionId,
      lastUpdatedOn: formatDateToServiceReq(lastUpdated),
      sortOrder: sortOrder,
    }

    if (productIdInt) {
      dataToSubmit.coaProductId = productIdInt
    }

    if (addCoa) {
      const response: { data: { addCoaProduct: { coaProductId: number } } } | any = await addProduct({
        variables: {
          input: dataToSubmit,
        },
      })
      response && setCurrentProductId(response.data?.addCoaProduct?.coaProductId)
      setAddCoa(false)
      addToast('Coa Was Added Successfully.', {
        appearance: 'success',
      })
    } else {
      await updateProduct({
        variables: {
          input: dataToSubmit,
        },
      })
      addToast('Coa Was Updated Successfully.', {
        appearance: 'success',
      })
      setEditCoa(false)
    }
    refetchCoaOnUpdate()
    setProductExists(true)
  }

  const createDateUpdatedOn = (): string => {
    const current = new Date()
    const dd = String(current.getDate()).padStart(2, '0')
    const mm = String(current.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy = current.getFullYear()
    let today = mm + '/' + dd + '/' + yyyy
    return today
  }

  return (
    <AddEditViewContainer>
      <div className='row-wrapper'>
        <div className='col-content'>
          <div className='common-content-wrapper'>
            <label className='common-lbl'>Name</label>
            <input type='text' value={productName} onChange={(e) => setProductName(e.target.value)} />
          </div>

          <div className='common-content-wrapper wiz-input'>
            <label className='common-lbl'>Display Name</label>
            <WysiwygEditor initalValue={displayName} updateValue={setDisplayName} />
          </div>
        </div>

        <div className='col-content'>
          <div className='common-content-wrapper'>
            <label className='common-lbl'>Region</label>
            <select
              name='regions'
              value={String(regionId)}
              id='regions'
              onChange={(e) => {
                setValidation(false)
                setRegionId(parseInt(e.target.value))
              }}
            >
              {regionData.map((region) => {
                return (
                  <option key={region.key} value={region.value}>
                    {region.title}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='common-content-wrapper'>
            <label className='common-lbl'>Section</label>
            <select
              name='sections'
              value={JSON.stringify(section)}
              id='sections'
              onChange={(e) => {
                setValidation(false)
                setSection(JSON.parse(e.target.value))
              }}
            >
              {sectionsData &&
                sectionsData.length > 0 &&
                sectionsData.map((section: any, index: any) => {
                  return (
                    <option key={index} value={JSON.stringify(section)}>
                      {section.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className='common-content-wrapper'>
            <label className='common-lbl'>Sort Order</label>
            <input type='text' value={sortOrder.toString()} onChange={(e) => setSortOrder(Number(e.target.value))} />
          </div>

          <div className='common-content-wrapper'>
            <label className='common-lbl'>Last Updated</label>
            <input
              type='date'
              value={lastUpdated}
              onChange={(e) => {
                setLastUpdated(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      {editCOA && (
        <div className='btns-container'>
          <StyledButton className='cancel-btn' onClick={() => setEditCoa(!editCOA)}>
            Cancel
          </StyledButton>
          <StyledButton className='save-btn' disabled={validation} onClick={handleSaveProduct}>
            Save
          </StyledButton>
        </div>
      )}
      {addCoa && (
        <div className='btns-container'>
          <StyledButton className='save-btn' disabled={validation} onClick={handleSaveProduct}>
            Save
          </StyledButton>
        </div>
      )}
    </AddEditViewContainer>
  )
}

const AddEditViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .row-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 2%;

    @media (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
    }

    .col-content {
      display: flex;
      width: 50%;
      flex-direction: column;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  .common-content-wrapper {
    display: flex;
    align-items: baseline;
    margin: 1% 0;
    &.wiz-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .common-lbl {
      font-size: 20px;
      margin-right: 20px;
      font-weight: 600;
      width: 130px;
      text-align: left;
    }

    select {
      border: 1px solid #0f4b8f;
      width: 250px;
      height: 35px;
    }

    input {
      border: 1px solid #0f4b8f;
      width: 250px;
      height: 35px;
      padding-left: 10px;
      font-size: 18px;
    }
  }

  .btns-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 15px;
  }
`
