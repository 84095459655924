import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

const useQueryForSingleAttribute = (type: any) => {
  // const [data, setData] = useState<any>()
  const [attributes, setAttributes] = useState<any>()
  useEffect(() => {
    setAttributes(type)
  }, [type])
  const [dataAttribute, setDataAttribute] = useState<any>()

  const { addToast } = useToasts()
  const GET_ATTRIBUTE_TYPE = gql`
    query getProductAttibuteType($productAttributeTypeId: Int!) {
      productAttributeTypes(where: { productAttributeTypeId: { eq: $productAttributeTypeId } }) {
        productAttributeTypeId
        name
        allowedOn
        backgroundColor
        foregroundColor
        sortOrder
        productAttributes {
          productAttributeId
          name
          sortOrder
        }
        attributeCount
      }
    }
  `
  const [getAttribute, { loading, error, data, refetch }] = useLazyQuery(GET_ATTRIBUTE_TYPE, {
    onCompleted(data) {},
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (data) {
      setDataAttribute(data)
    }
  }, [data, refetch])

  const GET_ATTRIBUTE_TYPES = gql`
    query getProductAttibuteType($productAttributeTypeId: Int!) {
      productAttributeTypes(where: { productAttributeTypeId: { eq: $productAttributeTypeId } }) {
        productAttributeTypeId
        name
        allowedOn
        backgroundColor
        foregroundColor
        sortOrder
        productAttributes {
          productAttributeId
          name
          sortOrder
        }
        attributeCount
      }
    }
  `

  const ADD_ATTRIBUTE_SINGLE = gql`
    mutation addProductAttribute($input: AddProductAttributeInput!) {
      addProductAttribute(input: $input) {
        productAttributeId
        name
        sortOrder
        attributes
        notes
        productAttributeTypeId
      }
    }
  `

  const EDIT_SINGLE_ATTRIBUTE = gql`
    mutation changeProductAttribute($input: ChangeProductAttributeInput!) {
      changeProductAttribute(input: $input) {
        productAttributeId
        name
        sortOrder
        attributes
        notes
      }
    }
  `

  const DELETE_SINGLE_ATTRIBUTE = gql`
    mutation removeProductAttribute($input: RemoveInput!) {
      removeProductAttribute(input: $input) {
        success
      }
    }
  `

  const GET_ATTRIBUTE_TYPES_REFRESH = gql`
    query getProductAttibuteTypes {
      productAttributeTypes(order: { sortOrder: ASC }) {
        productAttributeTypeId
        name
        allowedOn
        backgroundColor
        foregroundColor
        sortOrder
        productAttributes {
          productAttributeId
          name
        }
        attributeCount
      }
    }
  `

  const [deleteSingleAttribute, { data: deleteSingleAttributeData }] = useMutation(DELETE_SINGLE_ATTRIBUTE, {
    refetchQueries: [
      {
        query: GET_ATTRIBUTE_TYPE,
        variables: {
          productAttributeTypeId: (attributes && attributes.productAttributeTypeId && attributes.productAttributeTypeId) || 15,
        },
      },
      {
        query: GET_ATTRIBUTE_TYPES_REFRESH,
      },
    ],
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      console.log(error, 'error')
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    },
  })

  const [editSingleAttribute] = useMutation(EDIT_SINGLE_ATTRIBUTE, {
    refetchQueries: [
      {
        query: GET_ATTRIBUTE_TYPES,
        variables: {
          productAttributeTypeId: (attributes && attributes.productAttributeTypeId && attributes.productAttributeTypeId) || 15,
        },
      },
    ],
    onCompleted: (data) => {
      console.log('edit222222222222222222222222222', data)
      refetch()
    },
  })

  const [getAttributesTypes, { data: getAttributesTypesData }] = useLazyQuery(GET_ATTRIBUTE_TYPES_REFRESH)

  const [addAttributeSingle, { data: addAttributeSingleData, loading: loadingAttributeSingle, error: errorAttibuteSingle }] = useMutation(
    ADD_ATTRIBUTE_SINGLE,
    {
      refetchQueries: [
        {
          query: GET_ATTRIBUTE_TYPES,
          variables: {
            productAttributeTypeId: (attributes && attributes.productAttributeTypeId && attributes.productAttributeTypeId) || 15,
          },
        },
        {
          query: GET_ATTRIBUTE_TYPES_REFRESH,
        },
      ],
      onCompleted: () => {
        refetch()
      },
      fetchPolicy: 'no-cache',
    }
  )

  return {
    getAttribute,
    productAttribete: dataAttribute?.productAttributeTypes[0],
    addAttributeSingle,
    editSingleAttribute,
    refetch,
    deleteSingleAttribute,
  }
}

export default useQueryForSingleAttribute
