import gql from 'graphql-tag';

export const CHANGE_STARPOINT_INVENTORY = gql`
  mutation changeInventory($input: ChangeInventoryInput!) {
    changeInventory(input: $input) {
      inventoryId
    }
  }
`;

export const CHANGE_ALL_STARPOINT_INVENTORY = gql`
  mutation changeAllInventory($input: ChangeAllInventoryInput!) {
    changeAllInventory(input: $input) {
      inventoryId
    }
  }
`;
