import React, {useEffect}from "react";
import styled from "styled-components";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { REMOVE_USER } from "../utils/mutations";

function ConfirmDel(props) {

    const { addToast } = useToasts();
    const [removeUser] = useMutation(REMOVE_USER);
  const handleDeleteUser = async () => { 
    try{
      const { data } = await removeUser({ variables: { userId: props.id } });
      if(data.removeUser.success){
        props.onDelete(props.id)
        props.show()
      }
    }
    catch (error) {
        addToast('Delete was unsuccessful. Please refresh the page and try again. Contact IT if the problem continues.', {appearance: 'error'})
        props.show()

    }
}
    return (
        <Delete>
            <h1>Are You Sure You Want to Delete This {props.type}?</h1>
            <p>{props.name}</p>
            <ButtonRow><button id='del' onClick={handleDeleteUser}>Delete</button>
             <button id='cancel' onClick={props.show}>Cancel</button></ButtonRow>
        </Delete>
    )
}

export default ConfirmDel

const Delete = styled.div`

h1 {
  text-align: center;
  font-size: 28px;
}
p {
 font-size: 23px;
 text-align: center;
}
`;

const ButtonRow = styled.div`
  margin: 10% 5% 1% 5%;

  #del {
    margin: 0 0 0 20%;
    background: #d10000;
    color: white;
    font: normal Segoe UI;
    font-size: 16px;
    font-weight: 600;
    border: none;
    padding: 4px 20px;
  }
  #cancel {
    margin: 0 5%;
    background: #0f4b8f;
    color: white;
    font: normal Segoe UI;
    font-size: 16px;
    font-weight: 600;
    border: none;
    padding: 4px 20px;
  }
`;
