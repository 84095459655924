/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ProductDetailProvider } from './component-state/productDetailState'
import { Link, useParams } from 'react-router-dom'
import { GET_PRODUCT_PARENT, ADD_PRODUCT_PARENT_ATTRIBUTE, REMOVE_PRODUCT_PARENT_ATTRIBUTE } from '../../../utils/shopping/Shopping.Product.Queries'
import { GET_ATTRIBUTE_TYPES } from '../../../utils/shopping/Shopping.ProductTypes.Queries'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useToasts } from 'react-toast-notifications'

import { Row, Col, Container } from 'react-bootstrap'
import { ChevronDoubleLeft } from 'react-bootstrap-icons'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Attributes from './Attributes/Attributes'
import ChildProductList from './ChildProducts'
import Chip from '../../../GlobalComponents/Chip'

type ParentProductParams = {
  id: string
}

const ParentProductsDetails = () => {
  const { addToast } = useToasts()
  const { id } = useParams<ParentProductParams>()
  const { data, refetch } = useQuery(GET_PRODUCT_PARENT, {
    variables: { productParentId: Number(id) },
    fetchPolicy: 'no-cache',
  })
  const { data: attribtesData } = useQuery(GET_ATTRIBUTE_TYPES, {
    variables: { allowedOn: [1] },
    fetchPolicy: 'no-cache',
  })
  const [addProductAttributeProductParent] = useMutation(ADD_PRODUCT_PARENT_ATTRIBUTE)
  const [removeProductAttributeProductParent] = useMutation(REMOVE_PRODUCT_PARENT_ATTRIBUTE)

  const [parentProduct, setParentProduct] = useState<any>()
  const [inputList, setInputList] = useState<Array<any>>([])
  const [updateAttributes, setUpdateAttributes] = useState<Array<any>>([])
  const [existing, setExisting] = useState<Array<any>>([])
  const [key, setKey] = useState('products')
  const [resetAttribute, setResetAttributes] = useState<boolean>(false)

  useEffect(() => {
    id && data && setParentProduct(data.productParents.items[0])
  }, [data])

  useEffect(() => {
    if (resetAttribute) {
      setUpdateAttributes([])
      setResetAttributes(false)
    }
  }, [resetAttribute])

  useEffect(() => {
    if (parentProduct) {
      const extAttr = parentProduct.productAttributes.map((a: any) => {
        return { name: a.name.en, productAttributeId: a.productAttributeId }
      })
      setExisting(extAttr)
    }
  }, [parentProduct])

  useEffect(() => {
    attribtesData && setInputList(attribtesData.productAttributeTypes)
  }, [attribtesData])

  const ensureChanges = (mappedOne: Array<number>, mappedTwo: Array<number>) => {
    const delatArry = []
    mappedTwo.forEach((ele) => {
      if (!mappedOne.includes(ele)) {
        delatArry.push(ele)
      }
    })
    return mappedOne.length !== mappedTwo.length || delatArry.length > 0
  }

  const determineRemoved = (mappedOne: Array<number>, mappedTwo: Array<number>) => {
    const tempArry: any = []
    mappedOne.forEach((ele: any) => {
      !mappedTwo.includes(ele) && tempArry.push(ele)
    })

    return [tempArry.length > 0, tempArry]
  }

  const updatedAttributes = async (attrIds: Array<number>) => {
    try {
      await addProductAttributeProductParent({
        variables: {
          input: {
            productParentId: parentProduct.productParentId,
            productAttributeIds: attrIds,
          },
        },
      })
      addToast(`Attribute Updated Successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      })
      refetch()
    } catch (error) {
      addToast(`An Error Occured While Saving.`, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleRemoveAttibutes = async (attrIds: Array<number>) => {
    try {
      await removeProductAttributeProductParent({
        variables: {
          input: {
            productParentId: parentProduct.productParentId,
            productAttributeIds: attrIds,
          },
        },
      })
      addToast(`Attribute Updated Successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      })
      refetch()
    } catch (error) {
      addToast(`An Error Occured While Saving.`, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleSaveAttributes = async () => {
    if (parentProduct && parentProduct.productParentId) {
      const attrIds = updateAttributes.map((attr) => {
        return attr.productAttributeId
      })

      const mappedOne = existing.map((ele: any) => {
        return ele.productAttributeId
      })
      const mappedTwo = updateAttributes.map((ele: any) => {
        return ele.productAttributeId
      })

      const _eval = ensureChanges(mappedOne, mappedTwo)
      if (!_eval) {
        addToast(`No Updates To Save.`, {
          appearance: 'info',
          autoDismiss: true,
        })
        return
      }

      const evaluation = determineRemoved(mappedOne, mappedTwo)
      if (evaluation[0]) {
        handleRemoveAttibutes(evaluation[1])
      } else {
        updatedAttributes(attrIds)
      }
    }
  }

  return (
    <ProductDetailProvider>
      <ParentProductWrapper fluid>
        <Row>
          <Col lg={12}>
            <div className='top-nav-wrapper'>
              <Link to='/Shopping/ParentProducts' className='top-nav-link'>
                <div className='top-nav-inner-wrapper'>
                  <div className='top-nav-icon-wrapper'>
                    <ChevronDoubleLeft />
                  </div>
                  <div className='top-nav-text-wrapper'>
                    {' '}
                    <p>Back to Parent Products List</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className='top-section'>
          <Col lg={12}>
            <h3 className='page-title'>Parent Product Details</h3>
          </Col>
        </Row>
        <Row style={{ minHeight: '10vh' }}>
          <Col lg={6}>
            <Row>
              <Col sm={4}>
                <p className='bold-lbl-txt'>Name</p>
                <p className='bold-lbl-txt'>Freedom Parent Id</p>
              </Col>
              <Col sm={8}>
                <p>
                  <a
                    href={`https://admin.securefreedom.com/Zilis/inventory/invprices.asp?InventoryID=${parentProduct?.inventory.inventoryId}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {parentProduct?.inventory.productSku}
                  </a>
                </p>
                <p>{parentProduct?.inventory.inventoryId}</p>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Col sm={4}>
                <p className='bold-lbl-txt'>Attributes</p>
              </Col>
              <Col sm={8}>
                <div className='attr-chip-container'>
                  {parentProduct &&
                    parentProduct.productAttributes &&
                    parentProduct?.productAttributes.map((attr: any, index: number) => {
                      return (
                        <div key={index} className='attr-chip-wrapper'>
                          <Chip label={attr.name.en} color={attr.productAttributeType.backgroundColor} size={'LARGE'} />
                        </div>
                      )
                    })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='zls-divider'></div>
        <Row>
          <Col lg={12}>
            <Tabs id='controlled-tab' activeKey={key} onSelect={(k: any) => setKey(k)} className='mb-3 custom-tab' fill>
              <Tab eventKey='products' title='Products'>
                <ChildProductList childProducts={parentProduct && parentProduct.products} />
              </Tab>
              <Tab eventKey='attributes' title='Attributes'>
                <Attributes
                  inputList={inputList}
                  setUpdateAttributes={setUpdateAttributes}
                  existingProductAttributes={existing}
                  handleSaveAttributes={handleSaveAttributes}
                  setResetAttributes={setResetAttributes}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </ParentProductWrapper>
    </ProductDetailProvider>
  )
}

export default ParentProductsDetails

const ParentProductWrapper = styled(Container)`
  text-align: left;
  font-size: 18px;

  .top-nav-wrapper {
    margin-bottom: 20px;

    .top-nav-link {
      font-size: 20px;
      text-decoration: none;

      .top-nav-inner-wrapper {
        display: flex;
        align-items: center;

        .top-nav-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .top-nav-text-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .zls-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .top-section {
    margin-bottom: 2%;
    .page-title {
      font-size: 36px;
      color: rgb(92, 90, 90);
      font-weight: 450;
    }
  }

  .bold-lbl-txt {
    font-weight: 600;
  }

  .attr-chip-container {
    display: flex;
    flex-flow: row wrap;
    .attr-chip-wrapper {
      margin: 1px 2px;
    }
  }

  .custom-tab .nav-link.active {
    background-color: #0e4b8f;
    color: #fff;
  }
`
