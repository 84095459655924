import axios from 'axios'
import config from '../config/env-urls'

export const clearCacheAsync = async (payloadReq) => {
  try {
    return await axios.post(`${config.ENROLLMENTURL}/api/signupItems/refreshproductcache`, payloadReq)
  } catch (e) {
    return { status: 400, message: e.message }
  }
}
