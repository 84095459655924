/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useReducer } from 'react'
import * as PDA from './productDetailActions'
interface IInitalState {
  loading: boolean
  error: any
  products: any
  attributes: {
    selectedOption: any
    selectedList: any
    selectedAttributes: any
  }
  media: any
}

export const intialState: IInitalState = {
  loading: false,
  error: null,
  products: {},
  attributes: {
    selectedOption: {},
    selectedList: [],
    selectedAttributes: [],
  },
  media: {},
}

export const productDetailReducer = (state: IInitalState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case PDA.START_LOADING:
      return {
        ...state,
        loading: true,
      }
    case PDA.UPDATE_ATTRIBUTE_SELECTION:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          selectedOption: { ...action.payload },
        },
      }
    case PDA.SET_INITAL_ATTRIBUTES_SELECTION:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          attributesList: [...action.payload],
        },
      }
    case PDA.UPDATE_ATTRIBUTES_LIST:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          selectedAttributes: [...action.payload],
        },
      }
    default:
      return state
  }
}

export const ProductStore = createContext<{ state: IInitalState; dispatch: (action: any) => void }>({
  state: intialState,
  dispatch: (action: any) => {},
})

export const ProductDetailProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(productDetailReducer, intialState)
  const value = { state, dispatch }
  return <ProductStore.Provider value={value as any}>{children}</ProductStore.Provider>
}
